const ru = {
  change: "Изменить",
  confirm_password: "Подтвердить пароль",
  change_password: "Изменить пароль",
  old_password: "Старый пароль",
  new_password: "Новый пароль",
  select_staff: "Выберите штат",
  select_department: "Выберите подразделение",
  kasbiy_yuriqnomasi: "Квалификационные требование",
  lavozim_yuriqnomasi: "Должностная инструкция",
  tarkibiy_tuzilma: "Положение о структурном подразделении",
  my_documents: "Мои документы",
  is_main_branch: "Для Roodell",
  star: "Закладки",
  tabel: "Табель",
  view: "Просмотр",
  select_eimzo: "Выберите цифровой подпись",
  old_version: "Старая версия PDF",
  refresh_pdf: "Обновить PDF",
  toggle_menu: "Переключить меню",
  substantiate: "Обосновывать",
  archive: "Архив",
  where_to: "Куда",
  year: "год",
  salary_cert: "Справка о полученных доходах",
  year_month: "Год месяц",
  work_salary: "Заработная плата",
  bonus: "Премия",
  other_payments: "Другие выплаты, (за питание и др.)",
  income_tax: "Льготный подоходный налог",
  other_aumount: "Подоходный налог",
  alimony:
    "Прочие суммы (жил.облигации, удержание по исполнительным листам, алименты и другие)",
  total_payment: "Итого выплат",
  work_day: "Рабочий день",
  holiday: "Праздничный день",
  weekend: "Выходные дни",
  dashboard_all: "Всего",
  requiredpdf: "Принимается только pdf файл!",
  requiredDoc:
    "Принимается только (jpg,jpeg,png,xls,xlsx,xlsm,doc,docx,ppt,pptx,pdf) файл!",
  requiredsize: "Размер файла должен быть меньше 30 МБ!",
  requiredsize5: "Размер файла должен быть меньше 5 МБ!",
  reysters: "Каталоги(Реестры)",
  IT: "Информационные технологии: ",
  PM: "Кадр: ",
  PM_tashkent: "Кадр(Ташкент): ",
  tabel_acces_denied: "У вас нет доступа к этому табелному номеру.",
  signer: "Подписанты",
  acceptable: "Получатель",
  date: "Дата",
  rememberMe: "Запомни меня",
  itemsPerPageText: "",
  itemsPerPageAllText: "Все",
  company_id: "Организация",
  department_id: "Подразделения",
  staff_id: "штат",
  cancel: "Отмена",
  save: "Сохранить",
  logout: "Выйти",
  add: "Добавить",
  create: "Создать",
  created: "Создан",
  changed: "Изменено",
  published: "Объявлено",
  contacts: "Контакты для обращения",
  edit: "Редактировать",
  delete: "Удалить",
  close: "Закрыть",
  select: "Выберите",
  required: "Поля, отмеченные *, обязательны для заполнения!",
  input_required: "Это поле обязательно для заполнения",
  create_update_operation: "Успешно сохранено",
  swal_title: "Вы уверены?",
  swal_text: "это действие не может быть отменено позже",
  swal_delete: "Да, Удалить!",
  swal_ok: "Да",
  swal_deleted: "Ваш файл был удален!",
  swal_error_title: "Так!",
  swal_error_text: "Что-то пошло не так!",
  name_uz_latin: "Название узбекская латынь",
  name_uz_cyril: "Название узбекская кириллица",
  name_ru: "Название русский",
  actions: "Действие",
  name: "Имя",
  description_uz_cyril: "Описание кириллица",
  description_uz_latin: "Описание латинский",
  description_ru: "Описание русс",
  loadingText: "Загружается",
  noDataText: "Данные не найдены!",
  created_at: "Дата создания",
  updated_at: "Обновлено в",
  created_by: "Создан:",
  updated_by: "Обновлено:",
  deleted_at: "Удалено в",
  code: "Код",
  filter: "Фильтр",
  workflow: "Документооборот",
  organization: {
    index: "Организация",
    newOrganization: "Добавить новую организацию",
    updateOrganization: "Редактировать",
  },
  balance: "Баланс",
  created_documents: "Создавать документы",
  approved_documents: "Утвержденный документ",
  files: "Файлы",
  has_employee: "Работник прикреплен",
  start_date: "С",
  start_date2: "года",
  end_date: "До",
  all_documents: "Все документы",
  uploadFiles: "Загрузить файлы",
  publish: "опубликовать",
  expectation: "Ожидается",
  today: "Cегодня",
  yesterday: "Вчерашний день",
  week: "Неделя",
  oy: "Месяц",
  all: "Все",
  pending: "В процессе",
  resolution_results: "Исполненые резолюции",
  resolutions: "Резолюции",
  executor: "К исполнению",
  expired: "Истекает срок действия",
  expired_done: "Истек срок действия",
  negative: "отрицательный отзыв",
  done: "Завершено",
  qrCodeTitle:
    "Для проверки правильности указанных в документе данных, отсканируйте QR-код на мобильном телефоне и проверьте его через сайт предприятия.",
  ready_templates: "Готовые шаблоны",
  document_statistics: "Статистика документооборота",
  document_chart: "Диаграмма документооборота",
  look: "Посмотреть",
  download: "Скачать",
  more_than: "Более",
  and_less: "Менее",
  more_than_s: "более символов",
  and_less_s: "менее символов",
  confirm: "Подтвердить",
  search: "Поиск",
  searchInText: "Поиск из текста",
  document_transfer: "Передача документов",
  it_is_impossible:
    "Этого сотрудника нельзя уволить или перевести в другой штат !!!",
  unfinished_documents: "Имеет незаконченные документы",
  documents_transferred: "Документы можно передать следующим сотрудникам!",
  documents_transferred_employee:
    "Документы могут передаваться в следующие Штаты сотрудника!",
  for_info: "Для информации",
  structure_company: "Структура компании",
  total_documentation: "Общее количество подписей в системе ЭДО",
  EDS_without: "Количество подписей, утвержденных без ЭЦП в системе ЭДО",
  EDS_with: "Количество подписей, заверенных с ЭЦП в системе ЭДО",
  number: "Число",
  percent: "Процент",
  total_user: "Количество пользователей системы",
  user_eri: "Зарегистрированные пользователи с ЭЦП",
  user_ad: "Зарегистрированные пользователи без ЭЦП",
  ERI: "ЭЦП",
  university: "Место учёбы",
  study_type: "Тип обучения",
  major: "Специальность",
  academic_title: "Ученое звание",
  academic_degree: "Ученая степень",
  university_address: "Адрес место учёбы",
  tags: "Теги",
  cancel_documents: "Отмененные документы",
  Complaens: "Комплаенс",
  unblocked_users: "Разблокированные пользователи",
  input: {
    required: "Это поле обязательно для заполнения",
    text: "Ввод текста",
  },
  gender: {
    gender: "Пол",
    male: "Мужчина",
    female: "Женщина",
  },
  message: {
    change_password_success: "Пароль успешно изменен",
    new_password_and_confirm_password_does_not_match:
      "Подтверждение пароля не совпадает с новым паролем",
    old_password_is_incorrect: "Старый пароль не верный",
    change_password_error: "Ошибка при изменении пароля",
    signed_documents: "Подписанные документы",
    koreshok: "Расчетный корешок",
    koreshok_view: "Открыть расчетный корешок",
    lsp_report: "Отчеты ЛСП",
    lsp_index: "Отчеты по лист согласования платежа",
    document_template: "Шаблон документа",
    document_types: "Тип документа",
    signers_group: "Группа подписантов",
    positions: "Должность",
    noMessage: "Нет сообщений",
    noAlerts: "Нет оповещений",
    settings: "Настройки",
    create_new_document: "Создание нового документа",
    createInfo: "Создать новый справка",
    menu: "Меню",
    home: "Главная страница",
    create: "Создать новый документ",
    inbox: "Входящие",
    outbox: "Исходящие",
    draft: "Черновики",
    cancel: "Отмененный",
    sent: "Посланный",
    logout: "Выйти",
    myCompany: "Моя компания",
    profile: "Профиль",
    profileDialog: "Информация",
    tariffScale: "Тарифная сетка",
    coefficient: "Надбавка",
    departmentType: "Тип подразделений",
    personalType: "Тип персонала",
    positionType: "Тип должности",
    expenceType: "Тип расхода",
    requirementType: "Тип требования",
    ranges: "Разряд",
    natioanalities: "Национальность",
    districts: "Район",
    requirement: "Требования к должности",
    documents: "Документы",
    all: "Все",
    reports: "Отчеты",
    report: "Отчет",
    accessDepartment: "Доступ к отделам",
    accessType: "Типы доступа",
    requisites: "Реквизиты",
    currency: "Валюты",
    currencyHistory: "История валют",
    notifications: "Уведомления",
    znz: "Отчеты ЗНЗ",
    znz_index: "Отчеты по закупочным процессами",
    firm_blank: "Фирменная бланка",
    canceled: "Отмененный",
    countries: "Страна",
    regions: "Район",
  },
  company_requisites: {
    name_uz_latin: "Название узбекская латынь",
    name_uz_cyril: "Название узбекская кириллица",
    name_ru: "Название русский",
    name: "Наименование",
    address_name_uz_latin: "Адрес узбекская латынь",
    address_name_uz_cyril: "Адрес узбекская кириллица",
    address_name_ru: "Адрес русский",
    address: "Адрес",
    INN: "Номер ИНН",
    inn: "Номер ИНН",
    account: "Расчетный счет",
    swift: "SWIFT код",
    oknh: "ОКНХ код",
    mfo: "МФО",
    add_requisites: "Добавить новый реквизит",
    edit: "Редактировать",
    delete: "Удалить",
  },
  requestdoc: {
    index: "Организации, запрашивающие информацию",
    new: "Создать",
    update: "Редактировать",
    delete: "Удалить",
    unioncom: "В профком",
    bank: "В банк",
    tmib: "ТМИБ га",
    tmig: "К внешней миграции",
    embassy: "В посольство",
  },
  user: {
    password: "Пароль",
    department_name: "Название подразделение",
    roles: "Роли",
    online: "Онлайн пользователи",
    online_at: "Время последный действия",
    index: "Пользователи",
    dialogHeaderText: "Пользователи",
    name: "Имя",
    description_uz_cyril: "Описание кириллица",
    description_uz_latin: "Описание латинский",
    description_ru: "Описание русс",
    loadingText: "Загружается",
    noDataText: "Данные не найдены!",
    created_at: "Дата создания",
    updated_at: "Обновлено в",
    created_by: "Создан:",
    updated_by: "Обновлено:",
    deleted_at: "Удалено в",
    code: "Код",
    filter: "Фильтр",
    workflow: "Документооборот",
    organization: "Организация",
    balance: "Баланс",
    created_documents: "Создавать документы",
    approved_documents: "Утвержденный документ",
    files: "Файлы",
    has_employee: "Работник прикреплен",
    start_date: "С",
    start_date2: "года",
    end_date: "До",
    all_documents: "Все документы",
    uploadFiles: "Загрузить файлы",
    publish: "опубликовать",
    expectation: "Ожидается",
    today: "Cегодня",
    yesterday: "Вчерашний день",
    week: "Неделя",
    oy: "Месяц",
    all: "Все",
    pending: "В процессе",
    resolution_results: "Исполненые резолюции",
    resolutions: "Резолюции",
    executor: "К исполнению",
    expired: "Истекает срок действия",
    expired_done: "Истек срок действия",
    done: "Завершено",
    qrCodeTitle:
      "Для проверки правильности указанных в документе данных, отсканируйте QR-код на мобильном телефоне и проверьте его через сайт предприятия.",
    ready_templates: "Готовые шаблоны",
    document_statistics: "Статистика документооборота",
    document_chart: "Диаграмма документооборота",
    look: "Посмотреть",
    download: "Скачать",
    more_than: "Более",
    and_less: "Менее",
    more_than_s: "более символов",
    and_less_s: "менее символов",
    confirm: "Подтвердить",
    search: "Поиск",
    email: "Электронная почта",
    phone: "Телефон",
    department_id: "Подразделения",
    role_id: " Роль",
    position: "Должность",
    username: "Логин",
    newUser: "Добавить новый пользователь",
    updateUser: "Редактировать",
    "role-permission": "Роли -прикрепить разрешение",
    employee: "Сотрудник",
    employee_id: "Сотрудник",
    permission: "Разрешения",
    department_code: "Код подразделения",
    branch: "филиал",
  },
  lsp_report: "Отчеты ЛСП",
  lsp_index: "Отчеты по лист согласования платежа",
  document_template: "Шаблон документа",
  document_types: "Тип документа",
  signers_group: "Группа подписантов",
  positions: "Должность",
  noMessage: "Нет сообщений",
  noAlerts: "Нет оповещений",
  settings: "Настройки",
  createNewDocument: "Создание нового документа",
  createInfo: "Создать новую справку",
  menu: "Меню",
  inbox: "Входящие",
  outbox: "Исходящие",
  draft: "Черновики",
  canceled: "Отмененный",
  sent: "Посланный",
  myCompany: "Моя компания",
  profile: {
    profile: "Профиль",
    employee_name: "Ф.И.О",
    user_name: "Имя пользователя: ",
    tabel: "Табельный номер: ",
    born_date: "Дата рождения: ",
    department: "Подразделения: ",
    department_code: "Код подразделения",
    employee_position: "Должность: ",
    role: "Роль: ",
    email: "Электронная почта: ",
    imzo: "Получить ЭЦП",
    password: "Парол",
    stir: "ИНН",
    given_date: "Дата выпуска",
    expere_date: "Срок годности",
    push: "Прикрепить выделенные электронный ключ в профиль.",
    select_key: "Выберите ключ",
    incorrect_login: "Неверный логин или пароль",
    personal_information: "Персональная информация",
    all_documents: "Все документы",
    family_status: "Семейный статус",
    information: "Информация",
    order_date: "Дата приказа",
    order_number: "Номер приказа",
    first_work_date: "Первый день работы",
    adress: "Адрес",
    work_history: "История трудовой деятельности",
    education_history: "История образования",
    choose_key: "Прикрепить ЭЦП",
    tasks: "Задания",
    task_name: "Название задачи",
    add_task: "Добавить задачи",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    task_comment_index: "Работа выполнена по заданию",
    description: "Описание",
    file: "Файл",
  },
  tariffScale: {
    index: "Тарифная сетка",
    dialogHeaderText: "Тарифная сетка",
    category: "Категория",
    salary: "Оклад",
    order_date: "Дата приказа",
    order_number: "Номер приказа",
    create: "Добавить новая тарифная сетка",
    edit: "Редактировать",
  },
  coefficient: {
    index: "Надбавка",
    dialogHeaderText: "Надбавка",
    code: "Код",
    percent: "Процент",
    description: "Описание",
    order_date: "Дата приказа",
    order_number: "Номер приказа",
    status: "Статус",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    create: "Добавить новый надбавок",
    edit: "Редактировать",
    type: "Типы предпочтений",
  },
  personalType: {
    index: "Тип персонала",
    dialogHeaderText: "Тип персонала",
    create: "Добавить новый тип персонала",
    edit: "Редактировать",
  },
  positionType: {
    index: "Тип должность",
    dialogHeaderText: "Тип должность",
    code: "Код",
    create: "Добавить новый тип должность",
    edit: "Редактировать",
  },
  expenceType: {
    index: "Тип расхода",
    dialogHeaderText: "Тип расхода",
    create: "Добавить новый тип расхода",
    edit: "Редактировать",
  },
  requirementType: {
    index: "Типы требование",
    create: "Добавить новый тип",
    edit: "Редактировать",
    dialogHeaderText: "Типы требование",
  },
  ranges: {
    index: "Разряды",
    dialogHeaderText: "Разряд",
    code: "Код",
    name: "Название Разряд",
    minfond: "Минимум фонд",
    maxfond: "Максимум фонд",
    order_date: "Дата приказа",
    order_number: "Номер приказа",
    create: "Добавить новый диапазон",
    edit: "Редактировать",
  },
  countries: {
    index: "Страна",
    dialogHeaderText: "Страна",
    country_code: "Код страны",
  },
  districts: {
    index: "Район",
    region_id: "Область",
    dialogHeaderText: "Район",
  },
  regions: {
    index: "Область",
    dialogHeaderText: "Область",
    country_id: "Страна",
    region_id: "Область",
  },
  natioanalities: {
    index: "Национальность",
    dialogHeaderText: "Национальность",
    name_uz_latin: "Узбекская латынь",
  },
  requirement: {
    index: "Требования к должности",
    type: "Тип требования",
    dialogHeaderText: "Требования к должности",
    requirement_type_id: "Тип требование",
    newrequirement: "Создать требования",
    updaterequirement: "Редактировать требования",
  },
  documents: "Документы",
  report: {
    reports: "Отчеты",
    create_document: "Созданные документы",
    expired: "Просроченные",
    prosesing: " В процессе",
    waiting: " В ожидании",
    completed_on_time: "Завершено вовремя",
    failed_in_time: "Не завершено вовремя",
  },
  accessType: {
    index: "Типы доступа",
    create: "Добавить новый тип доступа",
    edit: "Редактировать",
  },
  requisites: "Реквизиты",
  currency: {
    index: "Валюты",
    title: "Виды валют",
    name: "Название",
    code: "Код",
    description: "Описание",
    add: "Добавить новый тип валюты",
    edit: "Редактировать",
    delete: "Вы действительно хотите удалить этот тип валюты?",
    history: "История валюты",
    price: "Курс",
    date: "Дата",
    search: "Поиск",
    oper: "Операции",
  },
  currencyHistory: "История валют",
  notifications: "Уведомления",
  znz: "Отчеты ЗНЗ",
  znz_index: "Отчеты по закупочным процессами",
  firm_blank: "Фирменная бланка",
  permission: {
    index: "Разрешения",
    name: "Название разрешения",
    display_name: "Показать имя",
    description: "Описание",
    dialogHeaderText: "Добавить новые разрешения",
    edit: "Редактировать",
  },
  role: {
    index: "Роли",
    name: "Наименование роль",
    display_name: "Отображаемое имя",
    description: "Примечание",
    assigned_permissions: "Назначенные разрешения",
    assigned_roles: "Назначенные роли",
    not_assigned_roles: "Не назначенные роли",
    not_assigned_permissions: "Не назначенные разрешения",
    dialogHeaderText: "Назначить разрешения для роль",
    addRole: "Добавить новую роль",
    assign_role: "Назначать роли",
    assign_permission: "Назначить разрешения",
  },
  department: {
    functional_parent_id: "Функциональное руководство",
    parent_id: "Основное подразделение",
    okd: "ОКД",
    name: "Наименование",
    department_type_id: "Тип подразделения",
    director: "Директор",
    company_id: "Организация",
    dialog: "Подразделения",
    index: "Подразделения",
    create: "Создать новый подразделения",
    company_name: "Наименование организации",
    department_code: "Код подразделения",
    department_name: "Название подразделения",
    departments: "Подразделения",
    tree: "Дерево подразделений",
    manager_staff: "Штат руководителья",
    management: "Управляющий персонал",
    itr: "Инж-тех-раб.",
    restitr: "Инж-тех-раб. Остальные",
    rab: "Рабочий",
    master: "Мастер",
    accountant: "Учётчик",
    brigadir: "Бригадир",
    worker: "Рабочие",
    internship: "В.т.ч. На стажировке",
    foreigner: "Иностранцы",
    maternity: "Декрет",
    women: "Женщины",
    branch: "Филиал",
  },
  departments: {
    name: "Наименование",
    department_type_id: "Тип подразделения",
    parent_id: "Основной подразделения",
    director: "Директор",
    company_id: "Организация",
    dialog: "Подразделения",
    index: "Подразделения",
    create: "Создать новый подразделения",
    company_name: "Наименование организации",
    department_code: "Код подразделения",
    department_name: "Название подразделения",
    departments: "Подразделения",
    tree: "Дерево подразделений",
    manager_staff: "Штат руководителья",
  },
  company: {
    index: "Организация",
    dialogHeaderText: "Организация",
    name: "Наименование организации",
    newOrganization: "Новая организация",
    updateCompany: "Изменить организацию",
  },
  staff: {
    coefficient: "Коэффициент",
    hr: "Кадровое администрирование",
    name_uz_latin: "Узбекская латынь",
    name_uz_cyril: "Узбекская кириллица",
    name_ru: "Русский",
    department_id: "Подразделения",
    position_id: "Должность",
    range_id: "Разряд",
    personal_type_id: "Тип персонала",
    expence_type_id: "Тип расхода",
    rate_count: "Количество ставок",
    personal_count: "Личный счет",
    order_date: "Дата приказа",
    order_number: "Номер приказа",
    begin_date: "Дата начала",
    end_date: "Дата завершения",
    editStaff: "Редактировать",
    newStaff: "Создать",
    staff: "Штатное расписание",
    staff_history: "История штатное расписании сотрудника",
    staff_admin: "Организационный менеджмент",
    index: "Штатное расписание",
    admin: "Админ",
    range_code: "Разряд",
    expence_type: "Тип расход",
    personal_type: "Тип персонала",
    employees_count: "Кол-во сотр.",
    branch_id: "Филиал",
    requirements: "Требовании для кандидата",
    instruction_file_path: "Ссылка для файл интрукции",
    uploadFiles: "Загрузить файл",
    fileType: "Тип файла",
    file: "Файл",
    files: "Файли",
    objectType: "Тип объекта",
    shift: "Смена",
  },
  documentTypes: {
    list: "Список",
    name: "Имя",
    index: "Тип документа",
    dialogHeaderText: "Тип документа",
  },
  document: {
    pre_agreement: "Предсогласование",
    arrive_date: "Дата поступления",
    signed_at: "Дата подписания",
    eimzo_info: "Информация о цифровом подписе",
    add_parent_document: "Прикрепленные документы",
    children_document: "Қуйи алоқадор ҳужжатлар",
    add_doer: "добавить исполнителя",
    document_sending_department: "Отправитель",
    document_number: "Номер документа",
    letter_number: "Почтовый номер",
    create: "Создание нового документа",
    new_document: "Новый документ",
    news_document: "Новые документы",
    parent_emlpoyee: "Направляющий персонал",
    edit_document: "Редактировать",
    edit_title: "Тема",
    document_type_id: "Тип документа",
    document_created_by: "Создан:",
    created_at: "Создано на:",
    confirmers: "Данные о ЭЦП",
    document_receiving_department: "Подразделения приема документов",
    attachments: "Вложения",
    title: "Тема",
    document_content: "Содержание документа",
    short_content: "Содержание",
    number: "Исходящий номер",
    correspondent: "Корреспондент",
    type: "Тип",
    should_receive_all_departments: "Должны получить все подразделенияы",
    documents: "Документы",
    document_type: "Тип документа",
    document_template: "Шаблон документа",
    document_date: "Дата документа",
    department: "Управления",
    department_send: "Отправитель",
    department_receiver: "Получатель",
    index: "Документы",
    comment: "Комментарий",
    assignment: "Поручение",
    content: "Кароткий описание",
    reject: "Отказ",
    documentTypes: "Типы документа",
    documentTemplates: "Шаблоны",
    action_type: "Действие",
    signer_sequence: "Последовательность",
    staff: "Подразделение, должность",
    signers: "Подписанты",
    history: "История документа",
    creator: "Автор- исполнитель",
    description: "Описание документа",
    reaction: "Действие",
    update: "Обновить",
    resolution: "Резолюция",
    resolution_create: "Дата резолюция",
    due_date: "Срок",
    files: "Файлы",
    add_file: "Добавить файл",
    file_name: "Имя файла",
    full_search: "Полный поиск",
    accept: "Одобрено",
    processing: "в процессе",
    vacancy: "Вакансия",
    doc_punkts: "Пункты документа",
    punkt: "Пункт",
    add_punkt: "Добавить Пункт",
    resolution_type: "Тип резолюция",
    inboxs: "Входящие документы",
    outboxs: "Исходящие документы",
    drafts: "Черновики документы",
    cancels: "Отмененный документы",
    inbox: "Входящие",
    outbox: "Исходящие",
    draft: "Черновики",
    expected: "Ожидаемые документы",
    canceled: "Отмененные",
    status: "Статус документa",
    term: "Срок (час)",
    add_signer: "Добавить подписывающего",
    document_name: "Название документа",
    add_parent: "Добавление прямых лидеров",
    empty_employee: "Сотрудник не присоединился",
    empty_document_relation: "Документ должен быть прикреплен",
    add_employee: "Добавить сотрудника",
    send: "Отправить",
    confirmation: "Подтверждение",
    return_to: "Возврат к исполнению",
    copy: "Копия из документа",
    not_set: "Не задано",
    start_date: "Дата начала",
    end_date: "Дата окончания",
    new: "Новые",
    ok: "Одобренные",
    cancel: "Отмененые",
    process: "в процесс",
    all: "Все документы",
    allhr: "Все документы(кадр)",
    alllsp: "Все документы(ЛСП)",
    allznz: "Все",
    reaction_status: "Статус подписания",
    doers: "Исполнители",
    doer: "Исполнитель",
    phone: "Тел",
    pending_action: "Ожидающее действие",
    total_sum: "Настоящая сумма",
    out_of_control: "Снять контроля",
    is_pdf_portrait: "такое PDF портрет?",
    edit_attribute: "Дополнительные атрибуты",
    when_who: "Кем и когда подписано",
    eri_given: "Кем выдан ЭЦП",
    eri_serial: "Серийный номер ЭЦП",
    eri_valid_date: "Срок действия ЭЦП",
    eri_info: "Данные электронной подписи документа",
    previous_version: "Предыдущая версия",
    company_outbox: "Исходящие (Uzavtosanoat)",
    add_resolution: "Добавить",
    "no-access": "У вас нет разрешения на просмотр этого документа!",
    back: "Назад",
    wrong_password: "Неправильный пароль!",
  },
  departmentTypes: {
    index: "Тип подразделения",
    dialogHeaderText: "Тип подразделения",
    create: "Добавить новый тип подразделения",
    edit: "Редактировать",
  },
  position: {
    index: "Должность",
    updatePosition: "Редактировать",
    newPosition: "Добавить новый должность",
  },
  recruitment: {
    index: "Прием на работу",
  },
  transfer: {
    index: "Перевод",
    transfer_employee: "Перевод сотрудника",
    create_document: "Создать документ",
    new_staff: "Новий штат",
    employee: "Сотрудник",
  },
  dismissal: {
    index: "Увольнение",
  },
  employeeDocument: {
    index: "Документы сотрудника",
    tabel: "Табельный номер :",
    born_date: "Дата рождения :",
    department: "Подразделения :",
    employee_position: "Должность :",
    series: "Серии",
    number: "Число",
    given_by: "Кем выдан",
    date_issue: "Дата выпуска",
    valid_until: "Срок годности",
    status: "Статус",
    actions: "Действия",
    addEmployeeDocument: "Добавить новые документы",
    updateEmployeeDocument: "Редактировать",
    docType: "Тип документа",
    title: "Название документа",
    files: "Файлы",
    uploadFiles: "Загрузить файлы",
  },
  employee: {
    index: "Сотрудники",
    image: "Загрузить изображения",
    deletedIndex: "Уволенные сотрудники",
    name: "Сотрудник",
    Company: "Организация",
    company_id: "Организация",
    country_id: "Страна",
    Nationality: "Национальность",
    nationality_id: "Национальность",
    region_id: "Область",
    district_id: "Район",
    address: "Адрес",
    email: "Электрон почта",
    tabel: "Табельный номер",
    firstname_uz_latin: "Имя (латин)",
    lastname_uz_latin: "Фамилия (латин)",
    middlename_uz_latin: "Отчество (латин)",
    firstname_uz_cyril: "Имя (кирилл.)",
    lastname_uz_cyril: "Фамилия (кирилл.)",
    middlename_uz_cyril: "отчество (кирилл.)",
    firstname: "Имя",
    lastname: "Фамилия",
    middlename: "Отчество",
    born_date: "Дата рождения",
    born_place: "Место рождения",
    INN: "ИНН",
    INPS: "ПИНФЛ",
    filter: "Поиск",
    born_date_from: "с (дата рождения)",
    born_date_to: "по (дата рождения)",
    department_id: "Подразделения",
    category: "Категория",
    shifr: "Шифр",
    contract_number: "Номер контракта",
    contract_date: "Дата контракта",
    position: "Должность",
    main_staff: "Основной штат",
    loaded_staff: "В загруженном состоянии",
    contractDate: "Дата контракта",
    contractNumber: "Номер контракта",
    enterOrderDate: "Дата Приказ",
    enterOrderNumber: "Номер Приказ",
    coefficients: "Надбавка",
    all: "Всего",
    enter_order_date: "Дата приказа",
    enter_order_number: "Номер приказа",
    percent: "Процент",
    description: "Описание",
    status: "Статус",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    active: "Активный",
    inactive: "Неактивный",
    addresses: "Адреса",
    country: "Страна",
    region: "Область",
    district: "Район",
    street_address: "Адрес улица",
    street_address_uz_latin: "Адрес улица Л",
    street_address_uz_cyril: "Адрес улица К",
    street_address_ru: "Адрес улица Р",
    home_address: "Домашний адрес",
    home_address_uz_latin: "Домашний адрес",
    home_address_uz_cyril: "Домашний адрес",
    home_address_ru: "Домашний адрес",
    staff_id: "Штат",
    tariff_scale_id: "Тарифная сетка",
    first_work_date: "Дата приема на работу",
    leave_order_number: "Номер приказа увольнения",
    leave_order_date: "Дата приказа увольнения",
    newEmployeeStaff: "Прием на работу",
    editEmployee: "Редактировать",
    is_main_staff: "Основной штат",
    employee_staff: "Штаты",
    range: "Разряд",
    editEmployeeStaff: "Редактировать штат сотрудника",
    editemployeeCoefficient: "Редактировать надбавки сотрудника",
    editEmployeeAddress: "Редактировать адрес сотрудника",
    newEmployeeCoefficient: "Создать надбавки сотрудника",
    address_type_id: "Тип адрес",
    order_date: "Дата приказа",
    newEmployeeAddress: "Создать адрес сотрудника",
    personalInformation: "Личные данные",
    education: "Образование, квалификация",
    spravka: "Справки",
    family: "Семя",
    work_exprience: "Трудовая деятелность",
    hr: "Кадровые документы",
    military: "Воинский учет",
    taxes: "Налог на доходы",
    fullname: "Ф.И.О.",
    new_employee: "Новый сотрудник",
    birth_address: "Адрес рождения",
    residence_address: "Адрес проживания",
    employee_phones: "Телефоны сотрудников",
    phones: "Телефоны",
    phone: "Телефон",
    phone_type: "Тип телефона",
    phone_number: "Номер телефона",
    add_new_phone: "Добавить новый телефон",
    add_new_education_history: "добавить новую историю образования",
    add_new_work_history: "Добавить новую работу",
    dismissal: "Увольнение",
    leave_date: "Дата увольнения",
    leaving_reason_id: "Причина увольнения",
    info: "Информация о сотрудниках",
    employee_relative: "Родственники сотрудников",
    work_place: "Рабочее место",
    living_place: "Места жительства",
    family_relative: "Родственник семьи",
    add_employee_relative: "Добавить родственников сотрудника",
    edit_employee_relative: "Изменить родственника сотрудника",
    errorDismissalEmployee: "Бириктирилган штатларни олиб ташланг!",
    language_name: "Название на иностранном языке",
    language: "Язык",
    level: "Уровень",
    add_language: "Добавить иностранный язык",
    edit_language: "Редактирование иностранного языка",
    select_language: "Выбор иностранного языка",
    party: "Партия",
    parties: "Партии",
    select_party: "Выберите партия",
    state_award: "Государственная награда",
    state_awards: "Государственные награды",
    select_state_award: "Выбор государственной награды",
    add_state_award: "Добавить государственную награду",
    military_rank: "Воинское звание",
    military_ranks: "Воинские звания",
    select_military_rank: "Выбор воинского звания",
    add_military_rank: "Добавление воинского звания",
  },
  signerGroup: {
    signer_group_id: "Группа подписантов",
    new_signer_group: "Создать группа подписантов",
    edit_signer_group: "Редактировать группа подписантов",
    staff_list: "Список штатов",
    add_signer: "Добавить подписывающего",
  },
  documentTemplates: {
    company: "Компания",
    signers: "Штаты подписавших",
    index: "Шаблоны документов",
    sequence: "Последовательность",
    attribute_name_uz_latin: "Наименование аттрибута (узб латин)",
    attribute_name_uz_cyril: "Наименование аттрибута (узб кирил)",
    attribute_name_ru: "Наименование аттрибута (русский)",
    data_type_id: "Тип дата",
    value_min_length: "Мин. длина значения",
    value_max_length: "Макс. длина значения",
    description: "Описание",
    attributeName: "Имя атрибута",
    sign_type: "Тип подписи",
    registry_type: "Реестры",
    due_day_count: "Срок(день)",
    is_from_to_department_show: "Показать разделы отправителя и получателя?",
    is_content_visible: "Показать контент?",
    is_pdf_portrait: "такое PDF портрет?",
    is_document_relation: "Нужно ли прикреплять документ?",
    numeration_type: "тип нумерации",
    template_code: "код шаблона",
    folder_code: "код папки",
    digital: "кол-во цифр.",
  },
  document_detail_attributes: {
    index: "Атрибуты",
  },
  document_detail_templates: {
    index: "Детали",
    content: "Содержание",
    attribute_name_uz_latin: "Наименование аттрибута (узб латин)",
    attribute_name_uz_cyril: "Наименование аттрибута (узб кирил)",
    attribute_name_ru: "Наименование аттрибута (русский)",
    data_type_id: "Тип дата",
    value_max_length: "Мин. длина значения",
    value_min_length: "Макс. длина значения",
    description: "Описание",
    content_uz_latin: "Композиция узбекская латынь",
    content_uz_cyril: "Композиция Узбек Кирилл",
    content_ru: "Ингредиенты Русские",
    is_list_vertical: "Список вертикальный?",
    is_registry_show: "Реестр показывает?",
    is_show: "Показывает?",
  },
  actionTypes: {
    index: "Типы действий",
    sequence: "Последовательность",
  },
  appeal_content: {
    index: "Содержание жалобы",
    create: "Янги қўшиш",
    edit: "Редактировать",
    dialogHeaderText: "Содержание жалобы",
  },
  object_type: {
    index: "Типы объекта",
    create: "Добавить новый объект",
    edit: "Редактировать",
    dialogHeaderText: "Добавить новый объект",
  },
  purchase_catalogs: {
    catalogs: "Торговые каталоги",
    add_catalog: "Добавить продукт (сервис)",
    edit_catalog: "Редактирование продукт (сервис)",
    code: "Код",
    part_number: "Номер детали",
    description: "Описание",
    material_content: "Материальное содержание",
    name_eng: "Название на английском языке",
    name_ru: "Название на русском языке",
    specification: "Спецификация",
    short_name: "Краткое имя",
    using_location: "Использование местоположения",
    using_purpose: "Целевое использование",
    measure: "Ед.изм.",
    manufacturer: "Производитель",
  },
  reasons: {
    index: "Причины увольнения",
    newReasons: "Укажите причины увольнения",
    updateReasons: "Редактировать причины увольнения",
  },
  dismissed: {
    employee: "Уволенные сотрудники",
  },
  partners: {
    index: "Поставщики",
    name: "Название поставщика",
    adress: "Адрес поставщика",
    bank_name: "Название банка поставщика",
    bank_adress: "Адрес банка поставщика",
    account: "Рассчётный счёт поставщика",
    swift_code: "SWIFT код",
    inn: "Номер ИНН",
    mfo: "МФО",
    add_partners: "Добавить нового партнера",
    edit: "Редактировать",
  },
  notification: {
    index: "Уведомления",
    content: "Содержание",
    is_active: "Статус",
    create: "Добавить новое уведомление",
    edit: "Редактировать",
    delete: "Вы действительно хотите удалить это уведомление?",
  },
  accessDepartment: {
    index: "Доступ к отделам",
    employee: "Сотрудники",
    department: "Подразделения",
    access: "Доступи",
    accessType: "Тип доступа",
    create: "Добавить новый доступ",
    tabel: "Табельный номер",
  },
  registry: {
    index: "Реестр",
    vacation: "Реестр приказ отпуск",
    business_trip: "Реестр приказ командировка",
    work_calendar: "Рабочий календарь",
  },
  carPurchase: {
    ERI: "Получить электронную подпись",
    index: "Заявка на покупку автомобиля",
    view: "Заявка на автомобиль",
    employee: "Сотрудник",
    carModel: "Модель автомобиля",
    comment: "Комментарий",
    status: "Статус",
    regStatus: "Статус реестра",
    accEmployee: "Бухгалтер",
    employeeId: "Сотрудник",
    file: "Файл",
    files: "Файлы",
    create: "Новая заявка",
    createdBy: "Создан",
    createdAt: "Дата",
    department: "Подразделения",
    position: "Должность",
    edit: "Редактировать",
  },
  blankTemplate: {
    index: "Шаблоны форм",
    create: "Создать шаблон",
    get: "Список бланков",
    blank_name: "Наименование бланка",
    status: "Статус",
    attribute_name: "Наименование атрибута",
    parameter_name: "Наименование параметра",
    cell_address:
      "Адрес ячейка (например: B" + Math.floor(Math.random() * 19 + 1) + ")",
    data_type_id: "Тип атрибута",
    file: "Файл",
    fileType: "Тип файла",
  },
  reportDepartment: {
    all: "Все",
    published: "Опубликованный",
    processing: "Обработка",
    signed: "Подписанный",
    ready: "Готовый",
    completed: "Завершенный",
    cancelled: "Отмененный",
    of_them: "Из них",
    done: "Выполнено",
    of_them_completed: "Из них выполнено",
    result: "Результат",
    on_performance: "на исполнении",
    for_execution: "к исполнению",
    overdue: "Просроченный",
    on_time: "в срок",
    out_of_date: "с нарушением срока",
    clarified: "Даны разъяснения",
    satisfied: "Удовлетворено:",
    up_to_1_day: "до 1 дня",
    up_to_2_3_days: "до 2-3 дней",
    more_than_3_days: "более 3 дней",
  },
  familyRelatives: {
    index: "Семья родственники",
    status: "Статус",
    add: "Добавить семью родственников",
  },
  inventory: {
    index: "Инвентарь",
    warehouse: "Склад",
    comission: "Комиссия",
    blank_number: "Номер формы",
    part_number: "Part№",
    product_name: "наименование товара",
    real_stock: "количество",
    address: "Адрес",
    addressName: "Адрес",
    delete: "Вы уверены, что хотите удалить этот адрес?",
    product: "Список продуктов",
    partNumber: "Номер детали",
    productName: "Остаток",
    stock: "Остаток",
    unitMeasure: "Единица измерения",
    report: "Отчет",
    commission: "Комиссия",
    blank: "Бланки",
  },
  directory: {
    index: "Журналы",
    type: "Тип журнала",
  },
  timeline: {
    index: "Лента",
    no_title: "Без названия",
    active: "Активный",
    inactive: "Неактивный",
    no_comment: "Пока без коментариев",
    comments: "Комментарии",
    leave_comment: "Оставить комментарий",
    to_comment: "Комментарий к ",
  },
  hr_language: {
    index: "Иностранный язык",
    add: "Добавить иностранный язык",
  },
  hr_party: {
    index: "Партия",
    add: "Добавить партия",
  },
  hr_study_type: {
    index: "Тип образования",
    add: "Добавить тип образования",
  },
  hr_state_award: {
    index: "Государственные награды",
    add: "Добавить государственные награды",
  },
  hr_military_rank: {
    index: "Военный должность",
    add: "Добавить",
  },
  sap_transaction: {
    index: "Роль транзакции (SAP)",
    add: "Добавить",
  },
  hr_university: {
    index: "Университет",
    add: "Добавить университет",
  },
  hr_major: {
    index: "Специальность в образовании",
    add: "Добавить специализацию",
  },
  hr_study_degree: {
    index: "Уровень образования",
    add: "Добавить уровень образования",
  },
  reports: {
    index: "Создать отчет",
    conditions: "Условия",
    columns: "Столбцы",
    column_table: "Таблица извлечения данных",
    column_name: "Информационная колонка",
    additions: "Дополнения",
    document_detail_employee: "Из персонала документа",
    document_detail_content: "Из свойств документа",
  },
  joint_venture: {
    index: "Предприятие системы",
    name: "Наименование",
    create: "Создать",
    edit: "Редактировать",
    manager: "Руководителья",
    manager_fio: "Руководителья Ф.И.О.",
  },
  critical: {
    index: "Критический персонал",
    count: "Число",
    employee: "Сотрудник",
    position: "Должность",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    description: "Описание",
    add_reserve_employee: "Добавить нового сотрудника в резерв",
  },
  control_punkt: {
    add: "Добавить контрольный пункт",
    edit: "Редактировать контрольный пункт",
    name: "Контрольный пункт",
    content: "Содержание",
    priority: "Приоритет",
    punkt_type: "Тип пункта",
    journal: "Журнал",
    controller: "Отв. За контроль",
    nominated: "Назначенный",
    distribution_list: "Список рассылки",
  },
  reports_executions: {
    title: "Детализированный отчет по исполнению документов ",
    sidebar: "Детализированный отчет по исполнению документов ",
    date: "Дата документa",
    number: "Регистрационный номер",
    type: "Тип документа",
    fullname: "Ответственный исполнитель",
    department_head: "Подразделение",
    due: "Срок исполнения",
    yesNo: "Отметка  об исполнении",
    at: "Дата исполнения",
    file_doc: "Скрепка документа",
    file: "Скрепка файл исполнителя",
    type_name: "type_name_ru",
    department: "department_ru",
    yes: "Yes",
    no: "No",
    fio: "fio_ru",
  },
  reports_contracts: {
    title: "Контракт отчет по исполнению документов ",
    sidebar: "Контракт отчет по исполнению документов ",
    date: "Дата документa",
    number: "Номер",
    type: "Тип документа",
    fullname: "Ответственный исполнитель",
    department_head: "Подразделение",
    due: "Срок исполнения",
    yesNo: "Отметка  об исполнении",
    at: "Дата исполнения",
    file_doc: "Скрепка документа",
    file: "Скрепка файл исполнителя",
    type_name: "type_name_ru",
    department: "department_ru",
    yes: "Yes",
    no: "No",
    fio: "fio_ru",
    company: "Компания",
    contragent: "Контрагент/вид расхода",
    dogovor: "Договор",
    date_contract: "Дата договора",
    valyuta: "Валюта (кроме UZS)",
    valyuta_summ: "Валютная сумма",
    summa: "Сумма",
    category: "Категория",
    description: "Примечание",
    "field-title": "Title",
    contractor: "contractor",
    notes: "notes",
  },
  telegram: "Телеграм группа",
  tutorial: "Руководство",
  phonebook: "Телефонная книжка",
  copyClipboard: "Копировать номер документа",
  "new-edo": "Virtual Office (Новый ЭДО)",
};
module.exports = ru;