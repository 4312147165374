const uz_cyril = {
  change: "Ўзгартириш",
  confirm_password: "Паролни тасдиқланг",
  change_password: "Паролни ўзгартириш",
  old_password: "Ески парол",
  new_password: "Янги парол",
  select_staff: "Штатни танлаш",
  select_department: "Бўлимни танлаш",
  kasbiy_yuriqnomasi: "Касб йўриқномаси",
  lavozim_yuriqnomasi: "Лавозим йўриқномаси",
  tarkibiy_tuzilma: "Tarkibiy tuzilma to`g`risidagi nizom",
  my_documents: "Менинг ҳужжатларим",
  is_main_branch: "Roodell учун",
  kordim: "Кўрдим",
  star: "Хатчўплар",
  tabel: "Табел",
  view: "Кўриш",
  select_eimzo: "Eimzo калитини танлаш",
  old_version: "PDF эски версия",
  refresh_pdf: "PDF қайта юклаш",
  toggle_menu: "Менюни очиб ёпиш",
  substantiate: "Асослаб беринг",
  archive: "Архив",
  where_to: "Қаерга",
  year: "йил",
  salary_cert: "Olingan daromadlar haqida malumotnoma",
  work_day: "Ish kunlari",
  holiday: "Bayram kunlari",
  weekend: "Dam olish kunlari",
  dashboard_all: "Барчаси",
  requiredpdf: "Фақат pdf файл",
  requiredDoc: "Фақат (jpg,jpeg,png,xls,xlsx,xlsm,doc,docx,ppt,pptx,pdf) файл",
  requiredsize: "Файл ҳажми 30 MB дан кам бўлиши керак!",
  requiredsize5: "Файл ҳажми 5 MB дан кам бўлиши керак!",
  reysters: "Каталог(Реестр)",
  IT: "Ахборот технологиялари: ",
  PM: "Kadr(Asaka): ",
  PM_tashkent: "Kadr(Toshkent): ",
  tabel_acces_denied: "Ushbu tabel raqam bilan ishlashga ruhsatingiz yo`q.",
  signer: "Имзоловчи",
  acceptable: "Қабул қилувчи",
  date: "Имзоланган вақт",
  rememberMe: "Эслаб қолиш",
  itemsPerPageText: "",
  itemsPerPageAllText: "Ҳаммаси",
  company_id: "Ташкилот",
  department_id: "Бўлим",
  staff_id: "Штат",
  cancel: "Бекор қилиш",
  save: "Сақлаш",
  logout: "Тизимдан чиқиш",
  add: "Қўшиш",
  create: "Янги яратиш",
  created: "Яратилган",
  changed: "Ўзгартирилди",
  published: "Эълон қилинди",
  contacts: "Мурожаат учун",
  edit: "Таҳрирлаш",
  delete: "Ўчириш",
  close: "Ёпиш",
  select: "Танлаш",
  required: "* билан белгиланган майдонлар мажбурий",
  input_required: "Ушбу майдон мажбурий",
  create_update_operation: "Маълумотлар базасига мувоффаққиятли сақланди",
  swal_title: "Ushbu amalni bajarishga aminmisiz?",
  swal_text: "Ushbu amalni keyin orqaga qaytarib bo`lmaydi",
  swal_delete: "Xa, o`chirilsin!",
  swal_ok: "Ҳа",
  swal_deleted: "Sizning faylingiz o`chirildi",
  swal_error_title: "Xosh!",
  swal_error_text: "Nimadir to`g`ri bo`lmadi!",
  name_uz_latin: "Nomi o`zbek lotin",
  name_uz_cyril: "Nomi o`zbek kiril",
  name_ru: "Номи русча",
  actions: "Амал",
  name: "Номи",
  description_uz_cyril: "Изох кирил",
  description_uz_latin: "Изох лотин",
  description_ru: "Изох рус",
  loadingText: "Юкланмоқда",
  noDataText: "Маълумот топилмади!",
  created_at: "Яратилган сана",
  updated_at: "Тахрирланган сана",
  created_by: "Яратган фойдаланувчи:",
  updated_by: "Тахрирлаган фойдаланувчи:",
  deleted_at: "Ўчирилган сана",
  code: "Коди",
  filter: "Филтр",
  workflow: "Ҳужжат айланмаси",
  balance: "Баланс",
  created_documents: "Яратилган ҳужжатлар",
  approved_documents: "Тасдиқланган ҳужжатлар",
  files: "Файллар",
  has_employee: "Ҳодим бириктириладими",
  start_date: "йил кунидан",
  end_date: "йил кунигача",
  all_documents: "Барча ҳужжатлар",
  uploadFiles: "Файлларни юклаш",
  publish: "Эълон қилиш",
  expectation: "Кутилмоқда",
  today: "Бугун",
  yesterday: "Кеча",
  week: "Ҳафта",
  oy: "Ой",
  all: "Ҳаммаси",
  pending: "Жараёнда",
  resolution_results: "Йўналтириш натижалари",
  resolutions: "Йўналтиришлар",
  executor: "Ижро учун келган ҳужжатлар",
  expired: "Муддати тугаётган",
  expired_done: "Муддати тугаган",
  negative: "Бекор қилинган ҳужжатлар",
  done: "Бажарилган",
  ready_templates: "Тайёр андозалар",
  document_statistics: "Ҳужжатлар айланмаси статистикаси",
  document_chart: "Ҳужжатлар айланмаси диаграммада",
  qrCodeTitle:
    "Ҳужжатда кўрсатилган маълумотларни тоғрилигини текшириш учун Qr-кодни мобил телефон орқали сканер қилинг ва корхона маълумотлар сайти орқали текширинг.",
  look: "Кўриш",
  download: "Юклаб олиш",
  more_than: "дан кўп",
  and_less: "дан кам",
  more_than_s: "дан кўп символ",
  and_less_s: "дан кам символ",
  confirm: "Тасдиқлаш",
  search: "Қидирув",
  searchInText: "Текстдан қидириш",
  document_transfer: "Ҳужжат ўтказиш",
  it_is_impossible:
    "Ушбу ҳодимни ишдан олиш ёки бошқа штатга ўтказиб бўлмайди !!!",
  unfinished_documents: "Тугатилмаган ҳужжатлари мавжуд",
  documents_transferred: "Ҳужжатларни қуйидаги ҳодимларга ўтказиш мумкин!",
  documents_transferred_employee:
    "Ҳужжатларни ҳодимнинг қуйидаги штатларига ўтказиш мумкин!",
  for_info: "Маълумот учун",
  structure_company: "Компаниянинг тузилиши",
  total_documentation: "ЭДО тизимида жами имзолар сони",
  EDS_without: "ЭДО тизимида ЭРИсиз тасдиқланган имзолар сони",
  EDS_with: "ЭДО тизимида ЭРИ билан тасдиқланган имзолар сони",
  number: "Сони",
  percent: "Фоиз",
  total_user: "Тизим фойдаланувчилари сони",
  user_eri: "Тизимда ЭРИ билан рўйҳатдан ўтган ҳодимлар",
  user_ad: "Тизимда ЭРИ сиз рўйҳатдан ўтган ҳодимлар",
  ERI: "ЭРИ",
  university: "Ўқиш жойи",
  study_type: "Таълим тури",
  major: "Мутахассислиги",
  academic_title: "Илмий унвони",
  academic_degree: "Илмий даражаси",
  university_address: "Ўқиш жойи манзили",
  tags: "Теглар",
  cancel_documents: "Отмененные документы",
  Complaens: "Комплаенс",
  unblocked_users: "Блокдан чиқарилган фойдаланувчилар",
  input: {
    required: "Ушбу майдон мажбурий",
    text: "Матн киритисh",
  },
  gender: {
    gender: "Жинси",
    male: "Эркак",
    female: "Аёл",
  },
  message: {
    change_password_success: "Пароль муваффаққиятли ўзгартирилди",
    change_password_error: "Пароль ўзгартиришда хатолик",
    new_password_and_confirm_password_does_not_match:
      "Тасдиқлаш паролни тўғри киритинг",
    old_password_is_incorrect: "Эски пароль нотўғри",
    signed_documents: "Имзоланган ҳужжатлар",
    koreshok: "Ойлик корешок",
    koreshok_view: "Ойлик корешок очиш",
    lsp_report: "ЛСП Ҳисоботлари",
    lsp_index: "ЛСП бўйича ҳисоботлар",
    document_template: "Ҳужжат шаблонлари",
    document_types: "Ҳужжат турлари",
    signers_group: "Имзо қоювчилар гуруҳи",
    positions: "Лавозимлар",
    noMessage: "Хабарлар йўқ",
    noAlerts: "Билдиришномалар йўқ",
    settings: "Созламалар",
    create: "Янги ҳужжат яратиш",
    createInfo: "Янги маълумотнома яратиш",
    menu: "Меню",
    home: "Асосий саҳифа",
    inbox: "Қабул қилинган ҳужжатлар",
    draft: "Қораламалар",
    outbox: "Чиқувчи ҳужжатлар",
    cancel: "Бекор қилинган ҳужжатлар",
    sent: "Юборилган ҳужжатлар",
    logout: "Тизимдан чиқиш",
    add: "Қўшиш",
    created: "Яратилган",
    changed: "Оъзгартирилди",
    published: "Эълон қилинди",
    contacts: "Мурожаат учун",
    edit: "Таҳрирлаш",
    delete: "Ўчириш",
    close: "Ёпиш",
    select: "Танланг",
    required: "* билан белгиланган майдонлар мажбурий",
    input_required: "Ушбу майдон мажбурий",
    create_update_operation: "Малумотлар базасига мувафаққиятли сақланди",
    swal_title: "Ушбу амални бажаришга аминмисиз?",
    swal_text: "Ушбу амални кейин орқага қайтариб бўлмайди",
    swal_delete: "Ха, ўчирилсин!",
    swal_ok: "Ҳа",
    swal_deleted: "Сизнинг файлингиз ўчирилди",
    swal_error_title: "Хош!",
    swal_error_text: "Нимадир тўғри бўлмади!",
    name_uz_latin: "Номи ўзбек лотин",
    name_uz_cyril: "Номи ўзбек кирил",
    name_ru: "Номи русча",
    actions: "Амал",
    name: "Номи",
    description_uz_cyril: "Изоҳ кирил",
    description_uz_latin: "Изоҳ лотин",
    description_ru: "Изоҳ русча",
    loadingText: "Юкланмоқда",
    noDataText: "Маълумот топилмади!",
    created_at: "Яратилган сана",
    updated_at: "Тахрирланган сана",
    created_by: "Яратган фойдаланувчи:",
    updated_by: "Тахрирлаган фойдаланувчи:",
    deleted_at: "Ўчирилган сана",
    code: "Коди",
    filter: "Фильтр",
    workflow: "Ҳужжат айланмаси",
    organization: "Ташкилот",
    balance: "Баланс",
    created_documents: "Яратилган ҳужжатлар",
    approved_documents: "Тасдиқланган ҳужжатлар",
    files: "Файллар",
    uploadFiles: "Файлларни юклаш",
    myCompany: "Менинг ташкилотим",
    profile: "Менинг профилим",
    profileDialog: "Менинг маълумотларим",
    tariffScale: "Тарифлар кўлами",
    coefficient: "Устамалар",
    departmentType: "Бўимлар турлари",
    personalType: "Персонал турлари",
    positionType: "Лавозимлар турлари",
    expenceType: "Харажат турлари",
    requirementType: "Талаб тури",
    ranges: "Диапазон",
    countries: "Давлат",
    regions: "Вилоят",
    natioanalities: "Миллат",
    districts: "Шаҳар/туман",
    requirement: "Ишга қўйиладиган талаблар",
    documents: "Ҳужжатлар",
    all: "Барчаси",
    report: "Ҳисобот",
    accessDepartment: "Ходимлар рухсати",
    accessType: "Рухсат турлари",
    requisites: "Реквизитлар",
    currency: "Валюталар",
    currencyHistory: "Валюталар тарихи",
    notifications: "Хабарномалар",
    znz: "ЗНЗ Ҳисоботлари",
    znz_index: "Ҳарид жараёнлари бўйича ҳисоботлар",
    firm_blank: "Фирма бланка",
  },
  employee: {
    index: "Ходим",
    deletedIndex: "Ишдан кетган ҳодимлар",
    name: "Ходим",
    Company: "Ташкилот",
    company_id: "Ташкитлот",
    country_id: "Давлат",
    Nationality: "Миллати",
    nationality_id: "Миллати",
    region_id: "Вилояти",
    district_id: "Шаҳар/туман",
    address: "Манзили",
    tabel: "Табел рақами",
    firstname_uz_latin: "Исми лотинча",
    lastname_uz_latin: "Фамиляси лотинча",
    middlename_uz_latin: "Отасининг исми лотинча",
    firstname_uz_cyril: "Исми кирил",
    lastname_uz_cyril: "Фамиляси кирил",
    middlename_uz_cyril: "Отасининг исми кирил",
    firstname: "Исми",
    lastname: "Фамилияси",
    middlename: "Отасининг исми",
    born_date: "Туғилган санаси",
    born_place: "Туғилган жойи",
    INN: "ИНН",
    INPS: "ЖШШИР",
    filter: "Қидирув",
    born_date_from: "Туғилган санаси(дан)",
    born_date_to: "Туғилган санаси(гача)",
    department_id: "Бўлимлар",
    category: "Категорияси",
    shifr: "Шифр",
    contract_number: "Шартнома рақами",
    contract_date: "Шартнома санаси",
    position: "Лавозими",
    main_staff: "Асосий штатда",
    loaded_staff: "Юклатилган штатда",
    contractDate: "Меҳ. шарт. санаси",
    contractNumber: "Меҳ. шарт. №",
    enterOrderDate: "Буйруқ санаси",
    enterOrderNumber: "Буйруқ рақами",
    coefficients: "Устамалар",
    all: "Жами",
    enter_order_date: "Буйруқ санаси",
    enter_order_number: "Буйруқ №",
    percent: "Миқдори(%)",
    description: "Изоҳ",
    status: "Статус",
    begin_date: "Бошл. санаси",
    end_date: "Тугаш санаси",
    active: "Актив",
    inactive: "Но актив",
    addresses: "Манзиллар",
    country: "Республика",
    region: "Вилоят",
    district: "Туман",
    street_address: "Маҳалла",
    street_address_uz_latin: "Маҳалла лотинчада",
    street_address_uz_cyril: "Маҳалла кирилчада",
    street_address_ru: "Маҳалла русчада",
    home_address: "Уй",
    home_address_uz_latin: "Лотинчада уй манзили",
    home_address_uz_cyril: "Кирилчада уй манзили",
    home_address_ru: "Русчада уй манзили",
    staff_id: "Штат",
    tariff_scale_id: "Тариф режаси",
    first_work_date: "Ишга қабул қилиш санаси",
    leave_order_number: "Ишдан бўшатиш буйруқ рақами",
    leave_order_date: "Ишдан бўшатиш буйруқ санаси",
    newEmployeeStaff: "Ишга ёллаш",
    editEmployee: "Таҳрирлаш",
    is_main_staff: "Асосий ходимлар",
    employee_staff: "Штатлар",
    range: "Бўшатиш",
    editEmployeeStaff: "Ходимлар таркибини таҳрирлаш",
    editemployeeCoefficient: "Ходимнинг нафақаларини таҳрирлаш",
    editEmployeeAddress: "Ходимнинг манзилини таҳрирлаш",
    newEmployeeCoefficient: "Ходимнинг нафақасини яратиш",
    address_type_id: "Манзил тури",
    order_date: "Буюртма санаси",
    newEmployeeAddress: "Ходимнинг манзилини яратиш",
    personalInformation: "Шахсий маълумотлар",
    education: "Таълим малака",
    spravka: "Маълумотномалар",
    family: "Оилавийлиги",
    work_exprience: "Иш фаолияти",
    hr: "Кадр Ҳужжатлари",
    military: "Харбий ҳисоб",
    taxes: "Даромад солиғи",
    fullname: "Ф.И.О.",
    new_employee: "Янги ходим",
    birth_address: "Туғилган манзили",
    residence_address: "Яшаш манзили",
    employee_phones: "Ходим телефонлари",
    phones: "Телефонлар",
    phone: "Телефон",
    phone_type: "Телефон тури",
    phone_number: "Телефон рақами",
    add_new_phone: "Янги телефон қўшиш",
    add_new_education_history: "Янги таълим маълумоти қўшиш",
    add_new_work_history: "Янги иш жойи қўшиш",
    dismissal: "Ишдан бўшатиш",
    leave_date: "Ишдан бўшаш санаси",
    leaving_reason_id: "Ишдан бўшаш сабаби",
    info: "Ходим ҳақида маълумот",
    employee_relative: "Ходимнинг қариндошлари",
    work_place: "Иш жойи",
    living_place: "Яшаш жойи",
    family_relative: "Қариндошлилик даражаси",
    add_employee_relative: "Ходимнинг қариндошларини қўшиш",
    edit_employee_relative: "Ходимнинг қариндошларини ўзгартириш",
    errorDismissalEmployee: "Бириктирилган штатларни олиб ташланг!",
    language_name: "Хорижий тил номи",
    language: "Тил",
    level: "Даражаси",
    add_language: "Хорижий тил қўшиш",
    edit_language: "Хорижий тилни таҳрирлаш",
    select_language: "Хорижий тилни танлаш",
    party: "Партия",
    parties: "Партиялар",
    select_party: "Партияни танланг",
    state_award: "Давлат мукофоти",
    state_awards: "Давлат мукофотлари",
    select_state_award: "Давлат мукофотини танлаш",
    add_state_award: "Давлат мукофоти қўшиш",
    military_rank: "Ҳарбий унвон",
    military_ranks: "Ҳарбий унвонлар",
    select_military_rank: "Ҳарбий унвонни танлаш",
    add_military_rank: "Ҳарбий унвон қўшиш",
    image: "Расм юклаш",
    email: "Электрон почта",
  },
  signerGroup: {
    signer_group_id: "Имзо қоювчилар гуруҳи",
    new_signer_group: "Имзоловчилар гуруҳини яратинг",
    edit_signer_group: "Имзоловчилар гуруҳини таҳрирлаш",
    staff_list: "Ходимлар рўйхати",
    add_signer: "Имзоловчиларни қўшиш",
  },
  documentTemplates: {
    signers: "Имзоловчилар штатлари",
    index: "Ҳужжат шаблонлари",
    sequence: "Навбат",
    attribute_name_uz_latin: "Атрибутнинг номи (узб лотин)",
    attribute_name_uz_cyril: "Атрибутнинг номи (Узбек Кирил)",
    attribute_name_ru: "Атрибут номи (рус)",
    data_type_id: "Сана тури",
    value_min_length: "Мин. қиймати узунлиги",
    value_max_length: "Макс. қиймати узунлиги",
    description: "Тавсиф",
    attributeName: "Атрибут номи",
    sign_type: "Имзолаш тури",
    registry_type: "Реэстр",
    is_from_to_department_show:
      "Юборувчи ва қабул қилувчи бўлимлари кўрсатилсинми?",
    is_content_visible: "Контент кўринсинми?",
    is_document_relation: "Ҳужжат бириктириш мажбурми?",
    numeration_type: "тип нумерации",
    template_code: "код шаблона",
    folder_code: "код папки",
    due_day_count: "Муддат (кун)",
  },
  document_detail_attributes: {
    index: "Хусусиятлар",
  },
  document_detail_templates: {
    index: "Тафсилотлар",
    content: "Таркиб",
    attribute_name_uz_latin: "Атрибутнинг номи (узб лотин)",
    attribute_name_uz_cyril: "Атрибутнинг номи (Узбек Кирил)",
    attribute_name_ru: "Атрибут номи (рус)",
    data_type_id: "Сана тури",
    value_max_length: "Мин. қиймати узунлиги",
    value_min_length: "Макс. қиймати узунлиги",
    description: "Тавсиф",
    content_uz_latin: "Таркиби ўзбек лотин",
    content_uz_cyril: "Таркиби ўзбек кирил",
    content_ru: "Таркиби русча",
    is_list_vertical: "Вертикал ҳолда кўрсатиладими?",
    is_registry_show: "Реистерда кўрсатиладими?",
    is_show: "Кўрсатиладими?",
  },
  actionTypes: {
    index: "Фаолият турлари",
    sequence: "Кетма-кетлик",
  },
  requirementType: {
    index: "Талаб турлари",
    create: "Янги қўшиш",
    edit: "Таҳрирлаш",
    dialogHeaderText: "Талаб турлари",
  },
  requirement: {
    index: "Ишга қўйиладиган талаблар",
    type: "Талаб тури",
    dialogHeaderText: "Ишга қўйиладиган талаблар",
    requirement_type_id: "Талаб тури",
    newrequirement: "Талабларни яратинг",
    updaterequirement: "Талабларни таҳрирлаш",
  },
  appeal_content: {
    index: "Шикоят мазмуни",
    create: "Янги қўшиш",
    edit: "Таҳрирлаш",
    dialogHeaderText: "Шикоят мазмуни",
  },
  object_type: {
    index: "Объект турлари",
    create: "Янги объект қўшиш",
    edit: "Таҳрирлаш",
    dialogHeaderText: "Янги объект қўшиш",
  },
  purchase_catalogs: {
    catalogs: "Харид каталоглари",
    add_catalog: "Харид каталоги қўшиш ",
    edit_catalog: "Харид каталогини таҳрирлаш",
    code: "Сап Коди",
    part_number: "Деталь рақами",
    description: "Изоҳ",
    material_content: "Материал таркиби",
    name_eng: "Номи инглиз тилида",
    name_ru: "Номи рус тилида",
    specification: "Спецификацияси",
    short_name: "Қисқа номи",
    using_location: "Жойлашувдан фойдаланиш",
    using_purpose: "Мақсадли фойдаланиш",
    measure: "Ўлчов",
    manufacturer: "Ишлаб чиқарувчи",
  },
  reasons: {
    index: "Ишдан бўшатилиш сабаблари",
    newReasons: "Ишдан бўшатилиш сабабларини қўшиш",
    updateReasons: "Ишдан бўшатилиш сабабларини таҳрирлаш",
  },
  dismissed: {
    employee: "Ишдан бўшатилган ходимлар",
  },
  partners: {
    index: "Ҳамкорлар",
    name: "Номи",
    adress: "Манзил",
    bank_name: "Банк номи",
    bank_adress: "Банк манзили",
    account: "Ҳисоб рақами",
    swift_code: "SWIFT коди",
    mfo: "МФО",
    inn: "ИНН",
    add_partners: "Янги ҳамкор қўшиш",
    edit: "Таҳрирлаш",
  },
  requisites: {
    name_uz_latin: "Номи ўзбек лотин",
    name_uz_cyril: "Номи ўзбек кирил",
    name_ru: "Номи русча",
    name: "Номи",
    address_name_uz_latin: "Манзил ўзбек лотин",
    address_name_uz_cyril: "Манзил ўзбек кирил",
    address_name_ru: "Манзил русча",
    address: "Манзили",
    INN: "ИНН рақами",
    account: "Ҳисоб рақами",
    swift: "SWIFT коди",
    oknh: "ОКНҲ",
    mfo: "МФО",
    add_requisites: "Янги реквизит қўшиш",
    edit: "Таҳрирлаш",
    delete: "Ўчириш",
  },
  requestdoc: {
    index: "Маълумотнома сўровчи ташкилотлар",
    new: "Яратиш",
    update: "Таҳрирлаш",
    delete: "Ўчириш",
    unioncom: "Касаба уюшма қўмитасига",
    bank: "Банкка",
    tmib: "ТМИБ га",
    tmig: "Ташқи миграцияга",
    embassy: "Элчихонага",
  },
  user: {
    password: "Пароль",
    department_name: "Бўлим номи",
    roles: "Роллар",
    index: "Фойдаланувчилар",
    dialogHeaderText: "Фойдаланувчилар",
    name: "Исм шарифи",
    online: "Онлине фойдаланувчилар",
    online_at: "Оҳирги амал вақти",
    email: "Электрон почтаси",
    phone: "Телефон рақами",
    department_id: "Бўлими",
    role_id: "Рўли",
    position: "Лавозими",
    username: "Логин",
    newUser: "Янги фойдаланувчи қўшиш",
    updateUser: "Таҳрирлаш",
    employee: "Ходим",
    "role-permission": "Ролларда - рухсатномани бириктиш",
    employee_id: "Ходим",
    permission: "Рухсатлар",
    department_code: "Бўлим коди",
    updated_at: "онлайн",
    branch: "филиал",
  },
  profile: {
    employee_name: "Ф.И.Ш",
    user_name: "Фойдаланувчи номи  ",
    key_name: "Фойдаланувчи номи  ",
    tabel: "Табел рақами  ",
    born_date: "Туғилган санаси  ",
    department: "Бўлим  ",
    department_code: "Бўлим коди",
    employee_position: "Лавозими  ",
    role: "Роли  ",
    email: "Электрон почта  ",
    imzo: "Электрон имзо",
    password: "Пароль",
    stir: "СТИР",
    given_date: "Берилган сана",
    expere_date: "Амал қилиш муддати",
    push: "Танланган имзони профилга бириктириш",
    select_key: "Калитни танланг",
    incorrect_login: "Логин ёки пароль нотўғри киритилган",
    personal_information: "Шахсий маълумотлари",
    all_documents: "Барча ҳужжатлари",
    family_status: "Оилавий ҳолати",
    information: "Маълумоти",
    order_date: "Буйруқ санаси",
    order_number: "Буйруқ рақами",
    first_work_date: "Биринчи иш санаси",
    adress: "Манзил",
    work_history: "Меҳнат фаолияти тарихи",
    education_history: "Таълим тарихи",
    choose_key: "Электрон имзо бириктириш",
    tasks: "Вазифалар",
    task_name: "Вазифа номи",
    add_task: "Вазифа қўшиш",
    begin_date: "Бошланиш вақти",
    end_date: "Тугаш муддати",
    task_comment_index: "Топшириқ бўйича қилинган ишлар",
    description: "Изоҳ",
    file: "Файл",
  },
  permission: {
    index: "Рухсатлар",
    name: "Рухсат номи",
    display_name: "Кўриниш номи",
    description: "Изох",
    dialogHeaderText: "Янги рухсат қўшиш",
    edit: "Таҳрирлаш",
  },
  role: {
    index: "Роллар",
    name: "Сарлавҳа роли",
    display_name: "Кўрсатиладиган исм",
    description: "Эслатма",
    assigned_permissions: "Бириктирилган рухсатномалар",
    not_assigned_permissions: "Бириктирилмаган рухсатномалар",
    assigned_roles: "Бириктирилган роллар",
    not_assigned_roles: "Бириктирилмаган роллар",
    assign_role: "Роль бирирктириш",
    assign_permission: "Рухсатнома бириктириш",
    dialogHeaderText: "Рухсатномаларни ролга тайинланг",
    addRole: "Янги роль қўшиш",
  },
  department: {
    functional_parent_id: "Функционал раҳбарият",
    parent_id: "Асосий бўлим",
    name: "Бўлим номи",
    department_type_id: "Бўлим тури",
    director: "Директор",
    company_id: "Ташкилот",
    dialog: "Бўлимлар",
    index: "Бўлимлар",
    create: "Янги бўлим яратиш",
    company_name: "Ташкилот номи",
    department_code: "Бўлим коди",
    department_name: "Бўлим номи",
    departments: "Бўлимлар",
    tree: "Бўлимлар иерарҳияси",
    manager_staff: "Раҳбар штати",
    management: "Бошқарувчи ходимлар",
    itr: "Инж-тех-ходим",
    restitr: "Инж-тех-ходим қолганлари",
    rab: "Ишчи",
    master: "Мастер",
    brigadir: "Бригадир",
    worker: "Ишчи",
    internship: "Амалиётда",
    foreigner: "Хорижликлар",
    maternity: "Декрет",
    women: "Аёллар",
    accountant: "Ҳисобчи",
    branch: "Филиал",
  },
  departments: {
    name: "Бўлим номи",
    department_type_id: "Бўлим тури",
    parent_id: "Асосий бўлим",
    director: "Директор",
    company_id: "Ташкилот",
    dialog: "Бўлимлар",
    index: "Бўлимлар",
    create: "Янги бўлим яратиш",
    company_name: "Ташкилот номи",
    department_code: "Бўлим коди",
    department_name: "Бўлим номи",
    departments: "Бўлимлар",
    tree: "Бўлимлар иерарҳияси",
    manager_staff: "Раҳбар штати",
  },
  company: {
    index: "Ташкилот",
    dialogHeaderText: "Ташкилот",
    name: "Ташкилот номи",
    newOrganization: "Янги ташкилот",
    updateCompany: "Ташкилотни тахрирлаш",
  },
  staff: {
    hr: "Кадрлар бошқаруви",
    name_uz_latin: "Ўзбек лотин",
    name_uz_cyril: "Ўзбек кирил",
    name_ru: "Русча",
    department_id: "Бўлими",
    position_id: "Касб",
    range_id: "Диапазон",
    personal_type_id: "Персонал тури",
    expence_type_id: "Харажат тури",
    rate_count: "Ставка сони",
    personal_count: "Персонал саноғи",
    order_date: "Буйруқ санаси",
    order_number: "Буйруқ рақами",
    begin_date: "Бошланиш санаси",
    end_date: "Тугалланиш санаси",
    editStaff: "Тахрирлаш",
    newStaff: "Янги киритиш",
    staff: "Штатлар жадвали",
    staff_history: "Ҳодимнинг штатлар жадвали тарихи",
    staff_admin: "Ташкилий бошқарув",
    index: "Штат жадвали",
    admin: "Админ панель",
    range_code: "Разряд",
    expence_type: "Ҳаражат тури",
    personal_type: "Персонал тури",
    employees_count: "Ходим сони",
    branch_id: "Филиал",
    requirements: "Кандидат учун талаблар",
    instruction_file_path: "Йўриқнома файлига хавола",
    uploadFiles: "Файлни юклаш",
    fileType: "Файл тури",
    file: "Файл",
    files: "Файллар",
    objectType: "Объект тури",
  },
  documentTypes: {
    list: "Рўйхат",
    name: "Номи",
    index: "Ҳужжат турлари",
    dialogHeaderText: "Ҳужжат турлари",
  },
  document: {
    pre_agreement: "Олдиндан келишув",
    arrive_date: "Келиб тушган сана",
    signed_at: "Имзоланган сана",
    eimzo_info: "Рақамли имзо ҳақида маълумотлар",
    add_parent_document: "Алоқадор ҳужжатлар",
    children_document: "Қуйи алоқадор ҳужжатлар",
    add_doer: "Бажарувчи қўшиш",
    document_sending_department: "Юборувчи",
    document_number: "Тартиб рақами",
    letter_number: "Хат рақами",
    create: "Янги ҳужжат яратиш",
    new_document: "Янги ҳужжат",
    new_documents: "Янги ҳужжатлар",
    news_document: "Янги ҳужжатлар",
    parent_emlpoyee: "Йўналтирувчи ходим",
    edit_document: "Таҳрирлаш",
    edit_title: "Мавзуни ўзгартириш",
    document_type_id: "Ҳужжат тури",
    document_created_by: "Яратган фойдаланувчи:",
    created_at: "Ҳужжат яратилган сана:",
    confirmers: "Эимзо маълумотлари",
    document_receiving_department: "Қабул қилувчилар",
    attachments: "Юклама",
    title: "Ҳужжат сарлавҳаси",
    document_content: "Ҳужжатнинг контент қисми",
    short_content: "Қисқача мазмуни",
    number: "Чиқувчи рақам",
    correspondent: "Корреспондент",
    type: "Тури",
    should_receive_all_departments: "Барча бўлимларга юбориш",
    documents: "Ҳужжатлар",
    document_type: "Тури",
    document_template: "Ҳужжат шаблонлари",
    document_date: "Санаси",
    department: "Бошқарув",
    department_send: "Юборувчи",
    department_receiver: "Қабул қилувчи",
    index: "Ҳужжатлар",
    comment: "Изоҳлар",
    assignment: "Топшириқ",
    content: "Қисқача мазмуни",
    reject: "Бекор қилиш",
    documentTypes: "Ҳужжат турлари",
    documentTemplates: "Андозалар",
    action_type: "Амал",
    signer_sequence: "Навбат",
    staff: "Бўлимлар, лавозимлар",
    signers: "Имзоловчилар",
    history: "Ҳужжат тарихи",
    creator: "Яратувчиси",
    description: "Ҳужжат тавсифи",
    reaction: "Амал",
    update: "Янгилаш",
    resolution: "Йўналтириш",
    resolution_create: "Йўналтирилган вақти",
    due_date: "Муддати",
    files: "Файллар",
    add_file: "Файл қўшиш",
    file_name: "Файл номи",
    full_search: "Тўлиқ қидирув",
    accept: "Тасдиқлайман",
    processing: "Жараёнда",
    vacancy: "Бўш жой",
    doc_punkts: "Ҳужжат пунктлари",
    punkt: "Пункт",
    add_punkt: "Пункт қўшиш",
    resolution_type: "Йўналтириш тури",
    inboxs: "Кирувчи ҳужжатлар",
    outboxs: "Чиқувчи ҳужжатлар",
    drafts: "Қоралама ҳужжатлар",
    cancels: "Бекор қилинган ҳужжатлар",
    inbox: "Кирувчи",
    outbox: "Чиқувчи",
    draft: "Қоралама",
    expected: "Кутилаётган ҳужжатлар",
    canceled: "Бекор қилинган",
    all: "Барча ҳужжатлар",
    allhr: "Барча док.(Кадр)",
    alllsp: "Барча док.(ЛСП)",
    allznz: "Барча дос.(ЗНЗ)",
    status: "Статус",
    term: "Муддат (соат)",
    add_signer: "Имзоловчиларни қўшиш",
    document_name: "Ҳужжат номи",
    add_parent: "Бевосита раҳбарларни қўшиш",
    empty_employee: "Ходим қўшилмади",
    empty_document_relation: "Ҳужжат илова қилиниши керак",
    add_employee: "Ходимни қўшинг",
    send: "Юбориш",
    confirmation: "Тасдиқлаш",
    return_to: "Ижрога қайтариш",
    copy: "Ҳужжатдан нусха олиш",
    not_set: "Ўрнатилмаган",
    start_date: "Бошланиш санаси",
    end_date: "Тугаш санаси",
    new: "Янги",
    ok: "Тасдиқланган",
    cancel: "Бекор қилиш",
    process: "Жараёнда",
    doers: "Ижрочилар",
    doer: "Ижрочи",
    reaction_status: "Имзолаш статуси",
    pending_action: "Кутилаётган ҳаракат",
    total_sum: "Ҳақиқий сумма",
    out_of_control: "Назоратдан олиш",
    is_pdf_portrait: "PDF портретми?",
    edit_attribute: "Қўшимча атрибутлар",
    when_who: "Ким томонидан ва қачон имзоланган",
    eri_given: "ЭРИ ким томонидан берилган",
    eri_serial: "ЭРИ серия рақами",
    eri_valid_date: "ЭРИ амал қилиш муддати",
    eri_info: "Ҳужжатнинг э-имзо маълумотлари",
    previous_version: "Олдинги версия",
    company_outbox: "Чиқувчи (Узавтосаноат)",
    add_resolution: "Қўшиш",
    "no-access": "Сизда ушбу ҳужжатни кўриш учун рухсат йўқ!",
    back: "Орқага",
    wrong_password: "Парол хато!",
  },
  home: {
    home: "Бош саҳифа",
  },
  tariffScale: {
    index: "Тариф сеткаси",
    dialogHeaderText: "Тарифлар",
    category: "Категорияси",
    salary: "Маош",
    order_date: "Буйруқ санаси",
    order_number: "Буйруқ рақами",
    create: "Янги тариф сеткаси қўшиш",
    edit: "Таҳрирлаш",
  },
  coefficient: {
    index: "Устамалар",
    dialogHeaderText: "Устамалар",
    code: "Коди",
    percent: "Фоиз",
    description: "Изоҳ",
    order_date: "Буйруқ санаси",
    order_number: "Буйруқ рақами",
    status: "Ҳолат",
    begin_date: "Бошланиш вақти",
    end_date: "Тугаш муддати",
    create: "Янги устама қўшиш",
    edit: "Таҳрирлаш",
    type: "Устама тури",
  },
  departmentTypes: {
    index: "Бўлим турлари",
    dialogHeaderText: "Бўлим турлари",
    create: "Янги бўлим тури киритиш",
    edit: "Таҳрирлаш",
  },
  personalType: {
    index: "Персонал турлари",
    dialogHeaderText: "Персонал турлари",
    create: "Янги персонал тури киритиш",
    edit: "Таҳрирлаш",
  },
  positionType: {
    index: "Лавозим турлари",
    dialogHeaderText: "Лавозим турлари",
    code: "Коди",
    create: "Янги лавозим турини киритиш",
    edit: "Таҳрирлаш",
  },
  position: {
    index: "Лавозимлар",
    updatePosition: "Таҳрирлаш",
    newPosition: "Янги лавозим қўшиш",
  },
  expenceType: {
    index: "Харажат турлари",
    dialogHeaderText: "Харажат турлари",
    create: "Янги харажат тури қўшиш",
    edit: "Таҳрирлаш",
  },
  ranges: {
    index: "Диапазонлар",
    dialogHeaderText: "Диапазон",
    code: "Коди",
    name: "Диапазон номи",
    minfond: "Минфонд",
    maxfond: "Махфонд",
    order_date: "Буйруқ санаси",
    order_number: "Буйруқ рақами",
    create: "Янги диапазон киритиш",
    edit: "Таҳрирлаш",
  },
  recruitment: {
    index: "Ишга ёллаш",
  },
  transfer: {
    index: "Таржима",
    transfer_employee: "Ҳодимларни бошқа штатга ўтказиш",
    create_document: "Документ ясаш",
    new_staff: "Янги Штат",
    employee: "Ҳодим",
  },
  dismissal: {
    index: "Ишдан бўшатиш",
  },
  countries: {
    index: "Давлат",
    dialogHeaderText: "Давлат",
    country_code: "Давлат коди",
  },
  districts: {
    index: "Шаҳар/туман",
    region_id: "Вилоят",
    dialogHeaderText: "Шаҳар/туман",
  },
  regions: {
    index: "Вилоят",
    dialogHeaderText: "Вилоят",
    country_id: "Давлат",
    region_id: "Вилоят",
  },
  natioanalities: {
    index: "Миллат",
    dialogHeaderText: "Миллат",
    name_uz_latin: "Ўзбекча лотин",
  },
  organization: {
    index: "Ташкилот",
    newOrganization: "Янги ташкилот қўшиш",
    updateOrganization: "Таҳрирлаш",
  },
  employeeDocument: {
    index: "Ходимнинг ҳужжатлари",
    tabel: "Табель рақами :",
    born_date: "Туғилган санаси :",
    department: "Бўлим :",
    employee_position: "Лавозими :",
    series: "Серия",
    number: "Рақам",
    given_by: "Ким томонидан берилган",
    date_issue: "Берилган сана",
    valid_until: "Амал қилиш муддати",
    status: "Статус",
    actions: "Амал",
    addEmployeeDocument: "Янги ҳужжатлар қўшиш",
    updateEmployeeDocument: "Таҳрирлаш",
    docType: "Ҳужжат тури",
    title: "Сарлавҳа",
    files: "Файллар",
    uploadFiles: "Файлларни юклаш",
  },
  report: {
    create_document: "Яратилган ҳужжатлар",
    expired: "Муддати ўтган",
    prosesing: "Кўриб чиқилмоқда",
    waiting: "Кутиш",
    completed_on_time: "Вақтида бажарилган",
    failed_in_time: "Вақтида бажарилмаган",
  },
  currency: {
    index: "Валюталар",
    title: "Валюта турлари",
    name: "Номи",
    code: "Коди",
    description: "Тавсифнома",
    add: "Янги валюта тури қўшиш",
    edit: "Таҳрирлаш",
    delete: "Сиз ушбу валюта турини ўчиришга аминмисиз?",
    history: "Валюталар тарихи",
    price: "Курс",
    date: "Сана",
    search: "Қидирув",
    oper: "Амаллар",
  },
  notification: {
    index: "Хабарномалар",
    content: "Таркиб",
    is_active: "Холат",
    create: "Янги хабарнома қўшиш",
    edit: "Таҳрирлаш",
    delete: "Сиз ушбу хабарномани ўчиришга аминмисиз?",
  },
  accessDepartment: {
    index: "Ходимлар рухсати",
    employee: "Ходимлар рўйхати",
    department: "Бўлимлар рўйхати",
    access: "Рухсатлар",
    accessType: "Рухсат тури",
    create: "Янги рухсат қўшиш",
    tabel: "Табель рақами",
  },
  accessType: {
    index: "Рухсат турлари",
    create: "Янги рухсат тури қўшиш",
    edit: "Таҳрирлаш",
  },
  registry: {
    index: "Реэстр",
    vacation: "Татил буйруғ реэстри",
    business_trip: "H.safari буйруғ реэстри",
    work_calendar: "Иш календари",
  },
  carPurchase: {
    ERI: "Электрон имзо олиш",
    index: "Автомобиль сотиб олиш учун ариза",
    view: "Автомобиль учун ариза",
    employee: "Ходим",
    carModel: "Автомобиль модели",
    comment: "Изоҳ",
    status: "Холат",
    regStatus: "Реэстр холати",
    accEmployee: "Хисобчи",
    employeeId: "Ходим",
    file: "Файл",
    files: "Файллар",
    create: "Янги ариза қўшиш",
    createdBy: "Яратган фойдаланувчи",
    createdAt: "Сана",
    department: "Бўлим",
    position: "Лавозим",
    edit: "Таҳрирлаш",
  },
  blankTemplate: {
    index: "Бланка шаблонлари",
    create: "Бланка яратиш",
    get: "Бланкалар рўйхати",
    blank_name: "Бланка номи",
    status: "Статус",
    attribute_name: "Атрибут номи",
    parameter_name: "Параметр номи",
    cell_address:
      "Ячейка манзили (масалан: " + Math.floor(Math.random() * 19 + 1) + ")",
    data_type_id: "Атрибут тури",
    file: "Файл",
    fileType: "Файл тури",
  },
  company_requisites: {
    name_uz_latin: "Номи ўзбек лотин",
    name_uz_cyril: "Номи ўзбек кирил",
    name_ru: "Номи русча",
    name: "Номи",
    address_name_uz_latin: "Манзил ўзбек лотин",
    address_name_uz_cyril: "Манзил ўзбек кирил",
    address_name_ru: "Манзил русча",
    address: "Манзили",
    inn: "ИНН рақами",
    account: "Ҳисоб рақами",
    swift: "SWIFT коди",
    oknh: "ОКНҲ",
    mfo: "МФО",
    add_requisites: "Янги реквизит қўшиш",
    edit: "Таҳрирлаш",
    delete: "Ўчириш",
  },
  reportDepartment: {
    all: "Барчаси",
    published: "Эълон қилинган",
    processing: "Жараёнда",
    signed: "Имзоланган",
    ready: "Тайёр",
    completed: "Якунланган",
    cancelled: "Рад эдилган",
  },
  familyRelatives: {
    index: "Оилавий қариндошлар",
    status: "Ҳолат",
    add: "Оилавий қариндошлар қўшиш",
  },
  inventory: {
    index: "Инвентаризация",
    warehouse: "Омбор",
    comission: "Комиссия",
    blank_number: "Бланка рақами",
    part_number: "Парт НО",
    product_name: "Масхулот номи",
    real_stock: "Саноқ",
    address: "Адрес",
    addressName: "Адрес номи",
    delete: "Сиз ушбу адресни ўчиришга аминмисиз?",
    product: "Махсулотлар рўйхати",
    partNumber: "Деталь рақами",
    productName: "Махсулот номи",
    stock: "Қолдиқ",
    unitMeasure: "Ўлчов бирлиги",
    report: "Ҳисобот",
    commission: "Комиссия",
    blank: "Бланкалар",
  },
  directory: {
    index: "Журналлар",
    type: "Журнал тури",
  },
  timeline: {
    index: "Лента",
    no_title: "Номсиз",
    active: "Актив",
    inactive: "Актив эмас",
    no_comment: "Ҳозирча изоҳлар йўқ",
    comments: "Изоҳлар",
    leave_comment: "Изоҳ қолдириш",
    to_comment: " га изоҳ",
  },
  hr_language: {
    index: "Хорижий тил",
    add: "Хорижий тил қўшиш",
  },
  hr_party: {
    index: "Партия",
    add: "Партия қўшиш",
  },
  hr_study_type: {
    index: "Таълим турлари",
    add: "Таълим турларини қўшиш",
  },
  hr_state_award: {
    index: "Давлат мукофотлари",
    add: "Давлат мукофотлари қўшиш",
  },
  hr_military_rank: {
    index: "Ҳарбий унвон",
    add: "Ҳарбий унвон қўшиш",
  },
  sap_transaction: {
    index: "Битим роли (САП)",
    add: "Рўл қўшиш",
  },
  hr_university: {
    index: "Олий ўқув юрти",
    add: "Олий ўқув юртини қўшиш",
  },
  hr_major: {
    index: "Маълумоти бўйича мутахассислиги",
    add: "Мутахассислигни қўшиш",
  },
  hr_study_degree: {
    index: "Таълим даражаси",
    add: "Таълим даражаси қўшиш",
  },
  reports: {
    index: "Ҳисобот яратиш",
    conditions: "Шартлар",
    columns: "Устунлар",
    column_table: "Маълумот олинадиган жадвал",
    column_name: "Маълумот устунти",
    additions: "Қўшимчалар",
    document_detail_employee: "Ҳужжат ҳодимларидан",
    document_detail_content: "Ҳужжат хусусиятларидан",
  },
  joint_venture: {
    index: "Тизим корҳонаси",
    name: "Тизим корҳонаси номи",
    create: "Янги яратиш",
    edit: "Ўзгартириш",
    manager: "Рахбари",
    manager_fio: "Рахбари Ф.И.О.",
  },
  critical: {
    index: "Стафф Критикал",
    count: "Сони",
    employee: "Ходим",
    position: "Лавозим",
    begin_date: "Бошланиш санаси",
    end_date: "Тугаш санаси",
    description: "Изоҳ",
    add_reserve_employee: "Захирага янги ходим қўшиш",
  },
  control_punkt: {
    add: "Контроль пунктини қўшинг",
    edit: "Контроль пунктини ўзгартириш",
    name: "Контроль пункт",
    content: "Таркиб",
    priority: "Устувор",
    punkt_type: "Пункт тури",
    journal: "Журнал",
    controller: "Назорат учун жавобгар",
    nominated: "Номзод",
    distribution_list: "Ижрочилар рўйхати",
  },
  reports_executions: {
    sidebar: "Ҳужжатларнинг бажарилиши бўйича батафсил ҳисобот",
    date: "Дата Документa",
    number: "Регистрационный номер",
    type: "Тип Документа Приказ",
    fullname: "Ответственны й исполнитель",
    department_head: "Подразделение",
    due: "Срок исполненИя",
    yesNo: "Отметка  об исполнении, Да/Нет",
    at: "Отметка  об исполнении, Дата исполнеНИЯ",
    file: "Скрепка файлисполнителя",
    type_name: "type_name_uz_cyril",
    department: "department_uz_cyril",
    yes: "Yes",
    no: "No",
    fio: "fio_uz_cyril",
  },
  reports_contracts: {
    title: " Шартнома бўйича тўлиқ хисобот",
    sidebar: "Шартнома бўйича тўлиқ хисобот",
    date: "Дата документa",
    number: "Номер",
    type: "Тип документа",
    fullname: "Ответственный исполнитель",
    department_head: "Подразделение",
    due: "Срок исполнения",
    yesNo: "Отметка  об исполнении",
    at: "Дата исполнения",
    file_doc: "Скрепка документа",
    file: "Скрепка файл исполнителя",
    type_name: "type_name_ru",
    department: "department_ru",
    yes: "Yes",
    no: "No",
    fio: "fio_ru",
    company: "Компания",
    contragent: "Контрагент/вид расхода",
    dogovor: "Договор",
    date_contract: "Дата договора",
    valyuta: "Валюта (кроме UZS)",
    valyuta_summ: "Валютная сумма",
    summa: "Сумма",
    category: "Категория",
    description: "Примечание",
    "field-title": "Title",
    contractor: "contractor",
    notes: "notes",
  },
  telegram: "Телеграм гурухи",
  tutorial: "Қўлланма",
  phonebook: "Ички телефон рақамлари",
  copyClipboard: "Ҳужжат рақамидан нусха олиш",
  "new-edo": "Virtual Office (Янги ЭДО)",
};
module.exports = uz_cyril;
