const uz_latin = {
  change: "O`zgartirish",
  confirm_password: "Parolni tasdiqlang",
  change_password: "Parolni o`zgartirish",
  old_password: "Eski parol",
  new_password: "Yangi parol",
  select_staff: "Shtatni tanlash",
  select_department: "Departmentni tanlash",
  kasbiy_yuriqnomasi: "Kasbiy yo`riqnomasi",
  lavozim_yuriqnomasi: "Lavozim yo`riqnomasi",
  tarkibiy_tuzilma: "Tarkibiy tuzilma to`g`risidagi nizom",
  my_documents: "Mening hujjatlarim",
  is_main_branch: "Roodell uchun",
  kordim: "Ko`rdim",
  star: "Xatcho`plar",
  tabel: "Tabel",
  view: "Ko`rish",
  select_eimzo: "Eimzo kalitini tanlang",
  old_version: "PDF eski versiyasi",
  refresh_pdf: "PDF qayta yuklash",
  toggle_menu: "Menyuni ochib yopish",
  substantiate: "Asoslab bering",
  archive: "Arxiv",
  where_to: "Qayerga",
  year: "yil",
  salary_cert: "Olingan daromadlar haqida malumotnoma",
  work_day: "Ish kunlari",
  holiday: "Bayram kunlari",
  weekend: "Dam olish kunlari",
  dashboard_all: "Barchasi",
  requiredpdf: "Faqat pdf fayl bo`lsin",
  requiredDoc:
    "Faqat (jpg,jpeg,png,xls,xlsx,xlsm,doc,docx,ppt,pptx,pdf) fayl bo`lsin",
  requiredsize: "Fayl hajmi 30 MB dan kam bo`lishi kerak!",
  requiredsize5: "Fayl hajmi 5 MB dan kam bo`lishi kerak!",
  reysters: "Katalog(Reestr)",
  IT: "Axborot texnologiyalari: ",
  PM: "Kadr(Asaka): ",
  PM_tashkent: "Kadr(Toshkent): ",
  tabel_acces_denied: "Ushbu tabel raqam bilan ishlashga ruhsatingiz yo`q.",
  signer: "Imzolovchi",
  acceptable: "Qabul qiluvchi",
  date: "Imzolangan vaqt",
  uzauto: "«UzAuto Motors» AJ",
  rememberMe: "Eslab qolish",
  itemsPerPageText: "",
  itemsPerPageAllText: "Hammasi",
  company_id: "Tashkilot",
  department_id: "Bo`lim",
  staff_id: "Shtat",
  cancel: "Bekor qilish",
  save: "Saqlash",
  logout: "Tizimdan chiqish",
  add: "Qo`shish",
  create: "Yangi yaratish",
  created: "Yaratilgan",
  changed: "О`zgartirildi",
  published: "E`lon qilindi",
  contacts: "Murojaat uchun",
  edit: "Tahrirlash",
  delete: "O`chirish",
  close: "Yopish",
  select: "Tanlang",
  required: "* bilan belgilangan maydonlar majburiy",
  input_required: "Ushbu maydon majburiy",
  create_update_operation: "Malumotlar bazasiga muvafaqqiyatli saqlandi",
  swal_title: "Ushbu amalni bajarishga aminmisiz?",
  swal_text: "Ushbu amalni keyin orqaga qaytarib bo`lmaydi",
  swal_delete: "Xa, o`chirilsin!",
  swal_ok: "Ha",
  swal_deleted: "Sizning faylingiz o`chirildi",
  swal_error_title: "Xosh!",
  swal_error_text: "Nimadir to`g`ri bo`lmadi!",
  name_uz_latin: "Nomi o`zbek lotin",
  name_uz_cyril: "Nomi o`zbek kiril",
  name_ru: "Nomi ruscha",
  actions: "Amal",
  name: "Nomi",
  description_uz_cyril: "Izoh kiril",
  description_uz_latin: "Izoh lotin",
  description_ru: "Izoh ruscha",
  loadingText: "Yuklanmoqda",
  noDataText: "Ma`lumot topilmadi!",
  created_at: "Yaratilgan sana",
  updated_at: "Taxrirlangan sana",
  created_by: "Yaratgan foydalanuvchi:",
  updated_by: "Taxrirlagan foydalanuvchi:",
  deleted_at: "O`chirilgan sana",
  code: "Kodi",
  filter: "Filtr",
  workflow: "Hujjat aylanmasi",
  balance: "Balans",
  created_documents: "Yaratilgan hujjatlar",
  approved_documents: "Tasdiqlangan hujjatlar",
  files: "Fayllar",
  has_employee: "Xodim biriktiladimi",
  start_date: "yil kunidan",
  end_date: "yil kunigacha",
  all_documents: "Barcha hujjatlar",
  uploadFiles: "Fayllarni yuklash",
  publish: "E`lon qilish",
  expectation: "Kutilmoqda",
  today: "Bugun",
  yesterday: "Kecha",
  week: "Hafta",
  oy: "Oy",
  all: "Hammasi",
  pending: "Jarayonda",
  resolution_results: "Yo`naltirish natijalari",
  resolutions: "Yo`naltirishlar",
  executor: "Ijro uchun kelgan hujjatlar",
  expired: "Muddati tugayotgan",
  expired_done: "Muddati tugagan",
  negative: "Bekor qilingan dokumentlar",
  done: "Bajarilgan",
  ready_templates: "Tayyor andozalar",
  document_statistics: "Hujjatlar aylanmasi statistikasi",
  document_chart: "Hujjatlar aylanmasi diagrammada",
  qrCodeTitle:
    "Hujjatda ko`rsatilgan ma`lumotlarni tog`riligini tekshirish uchun QR-kodni mobil telefon orqali skaner qiling va korxona ma`lumotlar sayti orqali tekshiring.",
  look: "Ko`rish",
  download: "Yuklab olish",
  more_than: "dan ko`p",
  and_less: "dan kam",
  more_than_s: "dan ko`p simvol",
  and_less_s: "dan kam simvol",
  confirm: "Tasdiqlash",
  search: "Qidiruv",
  searchInText: "Tekstdan qidirish",
  document_transfer: "Hujjat o`tkazish",
  it_is_impossible:
    "Ushbu hodimni ishdan olish yoki boshqa shtatga o`tkazib bo`lmaydi !!!",
  unfinished_documents: "Tugatilmagan hujjatlari mavjud",
  documents_transferred: "Hujjatlarni quyidagi hodimlarga o`tkazish mumkin!",
  documents_transferred_employee:
    "Hujjatlarni hodimning quyidagi shtatlariga o`tkazish mumkin!",
  for_info: "Ma`lumot uchun",
  structure_company: "Kompaniyaning tuzilishi",
  total_documentation: "EDO tizimida jami imzolar soni",
  EDS_without: "EDO tizimida ERIsiz tasdiqlangan imzolar soni",
  EDS_with: "EDO tizimida ERI bilan tasdiqlangan imzolar soni",
  number: "Soni",
  percent: "Foiz",
  total_user: "Tizim foydalanuvchilari soni",
  user_eri: "Tizimda ERI bilan ro`yhatdan o`tgan hodimlar",
  user_ad: "Tizimda ERI siz ro`yhatdan o`tgan hodimlar",
  ERI: "ERI",
  university: "O`qish joyi",
  study_type: "Ta`lim turi",
  major: "Mutaxassisligi",
  academic_title: "Ilmiy unvoni",
  academic_degree: "Ilmiy darajasi",
  university_address: "O`qish joyi manzili",
  tags: "Teglar",
  cancel_documents: "Отмененные документы",
  Complaens: "Комплаенс",
  unblocked_users: "Blokdan chiqarilgan foydalanuvchilar",
  organization: {
    index: "Tashkilot",
    newOrganization: "Yangi tashkilot qo`shish",
    updateOrganization: "Tahrirlash",
  },
  input: {
    required: "Ushbu maydon majburiy",
    text: "Matn kiritish",
  },
  gender: {
    gender: "Jinsi",
    male: "Erkak",
    female: "Ayol",
  },
  message: {
    change_password_success: "Parol muvaffaqqiyatli o`zgartirildi",
    change_password_error: "Parol o`zgartirishda xatolik",
    new_password_and_confirm_password_does_not_match:
      "Tasdiqlash parolni to`g`ri kiriting",
    old_password_is_incorrect: "Eski parol noto`g`ri",
    signed_documents: "Imzolangan hujjatlar",
    koreshok: "Oylik koreshok",
    koreshok_view: "Oylik koreshok ochish",
    lsp_report: "LSP Hisobotlari",
    lsp_index: "LSP bo`yicha hisobotlar",
    document_template: "Hujjat shablonlari",
    document_types: "Hujjat turlari",
    signers_group: "Imzo qoyuvchilar guruhi",
    positions: "Lavozimlar",
    noMessage: "Xabarlar yo`q",
    noAlerts: "Bildirishnomalar yo`q",
    settings: "Sozlamalar",
    create: "Yangi hujjat yaratish",
    createInfo: "Yangi ma`lumotnoma yaratish",
    menu: "Menyu",
    home: "Asosiy sahifa",
    draft: "Qoralamalar",
    inbox: "Kiruvchi hujjatlar",
    outbox: "Chiquvchi hujjatlar",
    cancel: "Bekor qilingan hujjatlar",
    sent: "Yuborilgan hujjatlar",
    logout: "Tizimdan chiqish",
    add: "Qo`shish",
    created: "Yaratilgan",
    changed: "О`zgartirildi",
    published: "E`lon qilindi",
    contacts: "Murojaat uchun",
    edit: "Tahrirlash",
    delete: "O`chirish",
    close: "Yopish",
    select: "Tanlang",
    required: "* bilan belgilangan maydonlar majburiy",
    input_required: "Ushbu maydon majburiy",
    create_update_operation: "Malumotlar bazasiga muvafaqqiyatli saqlandi",
    swal_title: "Ushbu amalni bajarishga aminmisiz?",
    swal_text: "Ushbu amalni keyin orqaga qaytarib bo`lmaydi",
    swal_delete: "Xa, o`chirilsin!",
    swal_ok: "Ha",
    swal_deleted: "Sizning faylingiz o`chirildi",
    swal_error_title: "Xosh!",
    swal_error_text: "Nimadir to`g`ri bo`lmadi!",
    name_uz_latin: "Nomi o`zbek lotin",
    name_uz_cyril: "Nomi o`zbek kiril",
    name_ru: "Nomi ruscha",
    actions: "Amal",
    name: "Nomi",
    description_uz_cyril: "Izoh kiril",
    description_uz_latin: "Izoh lotin",
    description_ru: "Izoh ruscha",
    loadingText: "Yuklanmoqda",
    noDataText: "Ma`lumot topilmadi!",
    created_at: "Yaratilgan sana",
    updated_at: "Taxrirlangan sana",
    created_by: "Yaratgan foydalanuvchi:",
    updated_by: "Taxrirlagan foydalanuvchi:",
    deleted_at: "O`chirilgan sana",
    code: "Kodi",
    filter: "Filtr",
    workflow: "Hujjat aylanmasi",
    organization: "Tashkilot",
    balance: "Balans",
    created_documents: "Yaratilgan hujjatlar",
    approved_documents: "Tasdiqlangan hujjatlar",
    files: "Fayllar",
    uploadFiles: "Fayllarni yuklash",
    myCompany: "Mening tashkilotim",
    profile: "Mening profilim",
    profileDialog: "Mening ma`lumotlarim",
    tariffScale: "Tariflar ko`lami",
    coefficient: "Ustamalar",
    departmentType: "Bo`imlar turlari",
    personalType: "Personal turlari",
    positionType: "Lavozimlar turlari",
    expenceType: "Xarajat turlari",
    requirementType: "Talab turi",
    ranges: "Diapazon",
    countries: "Davlat",
    regions: "Viloyat",
    natioanalities: "Millat",
    districts: "Shahar/tuman",
    requirement: "Ishga qo`yiladigan talablar",
    documents: "Hujjatlar",
    all: "Barchasi",
    report: "Hisobot",
    reports: "Hisobotlar",
    accessDepartment: "Xodimlar ruxsati",
    accessType: "Ruxsat turlari",
    requisites: "Rekvizitlar",
    currency: "Valyutalar",
    currencyHistory: "Valyutalar tarixi",
    notifications: "Xabarnomalar",
    znz: "ZNZ Hisobotlari",
    znz_index: "Harid jarayonlari bo`yicha hisobotlar",
    firm_blank: "Firma blanka",
  },
  employee: {
    index: "Xodim",
    deletedIndex: "Ishdan ketgan hodimlar",
    name: "Xodim",
    Company: "Tashkilot",
    company_id: "Tashkitlot",
    country_id: "Davlat",
    Nationality: "Millati",
    nationality_id: "Millati",
    region_id: "Viloyati",
    district_id: "Shahar/tuman",
    address: "Manzili",
    tabel: "Tabel raqami",
    firstname_uz_latin: "Ismi lotincha",
    lastname_uz_latin: "Familyasi lotincha",
    middlename_uz_latin: "Otasining ismi lotincha",
    firstname_uz_cyril: "Ismi kiril",
    lastname_uz_cyril: "Familyasi kiril",
    middlename_uz_cyril: "Otasining ismi kiril",
    firstname: "Ismi",
    lastname: "Familiyasi",
    middlename: "Otasining ismi",
    born_date: "Tug`ilgan sanasi",
    born_place: "Tug`ilgan joyi",
    INN: "INN",
    INPS: "JSHSHIR",
    filter: "Qidiruv",
    born_date_from: "Tug`ilgan sanasi(dan)",
    born_date_to: "Tug`ilgan sanasi(gacha)",
    department_id: "Bo`limlar",
    category: "Kategoriyasi",
    shifr: "Shifr",
    contract_number: "Shartnoma raqami",
    contract_date: "Shartnoma sanasi",
    position: "Lavozimi",
    main_staff: "Asosiy shtatda",
    loaded_staff: "Yuklatilgan shtatda",
    contractDate: "Meh.shart. sanasi",
    contractNumber: "Meh.shart. No.",
    enterOrderDate: "Buyruq sanasi",
    enterOrderNumber: "Buyruq raqami",
    coefficients: "Ustamalar",
    all: "Jami",
    enter_order_date: "Buyruq sanasi",
    enter_order_number: "Buyruq No",
    percent: "Miqdori(%)",
    description: "Izoh",
    status: "Status",
    begin_date: "Boshl. sanasi",
    end_date: "Tugash sanasi",
    active: "Aktiv",
    inactive: "No aktiv",
    addresses: "Manzillar",
    country: "Respublika",
    region: "Viloyat",
    district: "Tuman",
    street_address: "Mahalla",
    street_address_uz_latin: "Mahalla lotinchada",
    street_address_uz_cyril: "Mahalla kirilchada",
    street_address_ru: "Mahalla ruschada",
    home_address: "Uy",
    home_address_uz_latin: "Lotinchada Uy manzili",
    home_address_uz_cyril: "Kirilchada Uy manzili",
    home_address_ru: "Ruschada Uy manzili",
    staff_id: "Shtat",
    tariff_scale_id: "Tarif rejasi",
    first_work_date: "Ishga qabul qilish sanasi",
    leave_order_number: "Ishdan bo`shatish buyruq raqami",
    leave_order_date: "Ishdan bo`shatish to`g`risidagi buyruq sanasi",
    newEmployeeStaff: "Ishga yollash",
    editEmployee: "Tahrirlash",
    is_main_staff: "Asosiy xodimlar",
    employee_staff: "Shtatlar",
    range: "Bo`shatish",
    editEmployeeStaff: "Xodimlar tarkibini tahrirlash",
    editemployeeCoefficient: "Xodimning nafaqalarini tahrirlash",
    editEmployeeAddress: "Xodimning manzilini tahrirlash",
    newEmployeeCoefficient: "Xodimning nafaqasini yaratish",
    address_type_id: "Manzil turi",
    order_date: "Buyurtma sanasi",
    newEmployeeAddress: "Xodimning manzilini yaratish",
    personalInformation: "Shaxsiy ma`lumotlar",
    education: "Ta`lim malaka",
    spravka: "Ma`lumotnomalar",
    family: "Oilaviyligi",
    work_exprience: "Ish faoliyati",
    hr: "Kadr Hujjatlari",
    military: "Xarbiy hisob",
    taxes: "Daromad solig`i",
    fullname: "F.I.O.",
    new_employee: "Yangi xodim",
    birth_address: "Tug`ilgan manzili",
    residence_address: "Yashash manzili",
    employee_phones: "Xodim telefonlari",
    phones: "Telefonlar",
    phone: "Telefon",
    phone_type: "Telefon turi",
    phone_number: "Telefon raqami",
    add_new_phone: "Yangi telefon qo`shish",
    add_new_education_history: "Yangi ta`lim ma`lumoti qo`shish",
    add_new_work_history: "Yangi ish joyi qo`shish",
    dismissal: "Ishdan bo`shatish",
    leave_date: "Ishdan bo`shash sanasi",
    leaving_reason_id: "Ishdan bo`shash sababi",
    info: "Xodim haqida ma`lumot",
    employee_relative: "Xodimning qarindoshlari",
    work_place: "Ish joyi",
    living_place: "Yashash joyi",
    family_relative: "Qarindoshlilik darajasi",
    add_employee_relative: "Xodimning qarindoshlarini qo`shish",
    edit_employee_relative: "Xodimning qarindoshlarini o`zgartirish",
    errorDismissalEmployee: "Biriktirilgan shtatlarni olib tashlang!",
    language_name: "Xorijiy til nomi",
    language: "Til",
    level: "Darajasi",
    add_language: "Xorijiy til qo`shish",
    edit_language: "Xorijiy tilni tahrirlash",
    select_language: "Xorijiy tilni tanlash",
    party: "Partiya",
    parties: "Partiyalar",
    select_party: "Partiyani tanlang",
    state_award: "Davlat mukofoti",
    state_awards: "Davlat mukofotlari",
    select_state_award: "Davlat mukofotini tanlash",
    add_state_award: "Davlat mukofoti qo`shish",
    military_rank: "Harbiy unvon",
    military_ranks: "Harbiy unvonlar",
    select_military_rank: "Harbiy unvonni tanlash",
    add_military_rank: "Harbiy unvonni qo`shish",
    image: "Rasm yuklash",
    email: "Elektron pochta",
  },
  documentTemplates: {
    signers: "Imzolovchilar shtatlari",
    index: "Hujjat shablonlari",
    sequence: "Navbat",
    attribute_name_uz_latin: "Atributning nomi (uzb lotin)",
    attribute_name_uz_cyril: "Atributning nomi (Uzbek Kiril)",
    attribute_name_ru: "Atribut nomi (rus)",
    data_type_id: "Sana turi",
    value_min_length: "Min. qiymati uzunligi",
    value_max_length: "Maks. qiymati uzunligi",
    description: "Tavsif",
    attributeName: "Atribut nomi",
    sign_type: "Imzolash turi",
    registry_type: "Reestr",
    is_from_to_department_show:
      "Yuboruvchi va qabul qiluvchi bo`limlari ko`rsatilsinmi?",
    is_content_visible: "Kontent ko`rinsinmi?",
    is_document_relation: "Hujjat biriktirish majburmi?",
    numeration_type: "тип нумерации",
    template_code: "код шаблона",
    folder_code: "код папки",
    due_day_count: "Muddat (kun)",
  },
  document_detail_attributes: {
    index: "Xususiyatlar",
  },
  document_detail_templates: {
    index: "Tafsilotlar",
    content: "Tarkib",
    attribute_name_uz_latin: "Atributning nomi (uzb lotin)",
    attribute_name_uz_cyril: "Atributning nomi (Uzbek Kiril)",
    attribute_name_ru: "Atribut nomi (rus)",
    data_type_id: "Sana turi",
    value_max_length: "Min. qiymati uzunligi",
    value_min_length: "Maks. qiymati uzunligi",
    description: "Tavsif",
    content_uz_latin: "Tarkibi o`zbek lotin",
    content_uz_cyril: "Tarkibi o`zbek kiril",
    content_ru: "Tarkibi ruscha",
    is_list_vertical: "Vertikal holda ko`rsatiladimi?",
    is_registry_show: "Reisterda ko`rsatiladimi?",
    is_show: "Ko`rsatiladimi?",
  },
  actionTypes: {
    index: "Faoliyat turlari",
    sequence: "Ketma-ketlik",
  },
  requirementType: {
    index: "Talab turlari",
    create: "Yangi qo`shish",
    edit: "Tahrirlash",
    dialogHeaderText: "Talab turlari",
  },
  requirement: {
    index: "Ishga qo`yiladigan talablar",
    type: "Talab turi",
    dialogHeaderText: "Ishga qo`yiladigan talablar",
    requirement_type_id: "Talab turi",
    newrequirement: "Talablarni yarating",
    updaterequirement: "Talablarni tahrirlash",
  },
  appeal_content: {
    index: "Shikoyat mazmuni",
    create: "Yangi qo`shish",
    edit: "Tahrirlash",
    dialogHeaderText: "Shikoyat mazmuni",
  },
  object_type: {
    index: "Obyekt turlari",
    create: "Yangi obyekt qo`shish",
    edit: "Tahrirlash",
    dialogHeaderText: "Yangi obyekt qo`shish",
  },
  reasons: {
    index: "Ishdan bo`shatilish sabablari",
    newReasons: "Ishdan bo`shatilish sabablarini qo`shish",
    updateReasons: "Ishdan bo`shatilish sabablarini tahrirlash",
  },
  dismissed: {
    employee: "Ishdan bo`shatilgan xodimlar",
  },
  partners: {
    index: "Hamkorlar",
    name: "Nomi",
    adress: "Manzil",
    bank_name: "Bank nomi",
    bank_adress: "Bank manzili",
    account: "Hisob raqami",
    swift_code: "SWIFT kodi",
    mfo: "MFO",
    inn: "INN",
    add_partners: "Yangi hamkor qo`shish",
    edit: "Tahrirlash",
  },
  requisites: {
    name_uz_latin: "Nomi o`zbek lotin",
    name_uz_cyril: "Nomi o`zbek kiril",
    name_ru: "Nomi ruscha",
    name: "Nomi",
    address_name_uz_latin: "Manzil o`zbek lotin",
    address_name_uz_cyril: "Manzil o`zbek kiril",
    address_name_ru: "Manzil ruscha",
    address: "Manzili",
    INN: "INN raqami",
    account: "Hisob raqami",
    swift: "SWIFT kodi",
    oknh: "OKNH",
    mfo: "MFO",
    add_requisites: "Yangi rekvizit qo`shish",
    edit: "Tahrirlash",
    delete: "O`chirish",
  },
  requestdoc: {
    index: "Ma`lumotnoma so`rovchi tashkilotlar",
    new: "Yaratish",
    update: "Tahrirlash",
    delete: "O`chirish",
    unioncom: "Kasaba uyushma qo`mitasiga",
    bank: "Bankka",
    tmib: "TMIB ga",
    tmig: "Tashqi migratsiyaga",
    embassy: "Elchixonaga",
  },
  user: {
    password: "Parol",
    department_name: "Bo`lim nomi",
    roles: "Rollar",
    index: "Foydalanuvchilar",
    dialogHeaderText: "Foydalanuvchilar",
    name: "Ism sharifi",
    online: "Online foydalanuvchilar",
    online_at: "Ohirgi amal vaqti",
    email: "Elektron pochtasi",
    phone: "Telefon raqami",
    department_id: "Bo`limi",
    role_id: "Ro`li",
    position: "Lavozimi",
    username: "Login",
    newUser: "Yangi foydalanuvchi qo`shish",
    updateUser: "Tahrirlash",
    employee: "Xodim",
    "role-permission": "Rollarda - ruxsatnomani biriktish",
    employee_id: "Xodim",
    permission: "Ruxsatlar",
    department_code: "Bo`lim kodi",
    updated_at: "onlayn",
    branch: "filial",
  },
  profile: {
    employee_name: "F.I.Sh",
    user_name: "Foydalanuvchi nomi  ",
    key_name: "Foydalanuvchi nomi  ",
    tabel: "Tabel raqami  ",
    born_date: "Tug`ilgan sanasi  ",
    department: "Bo`lim  ",
    department_code: "Bo`lim kodi",
    employee_position: "Lavozimi  ",
    role: "Roli  ",
    email: "Elektron pochta  ",
    imzo: "Elektron imzo",
    password: "Parol",
    stir: "STIR",
    given_date: "Berilgan sana",
    expere_date: "Amal qilish muddati",
    push: "Tanlangan imzoni profilga biriktirish",
    select_key: "Kalitni tanlang",
    incorrect_login: "Login yoki parol noto‘g‘ri kiritilgan",
    personal_information: "Shaxsiy ma`lumotlari",
    all_documents: "Barcha hujjatlari",
    family_status: "Oilaviy holati",
    information: "Ma`lumoti",
    order_date: "Buyruq sanasi",
    order_number: "Buyruq raqami",
    first_work_date: "Birinchi ish sanasi",
    adress: "Manzil",
    work_history: "Mehnat faoliyati tarixi",
    education_history: "Ta`lim tarixi",
    choose_key: "Elektron imzo biriktirish",
    tasks: "Vazifalar",
    task_name: "Vazifa nomi",
    add_task: "Vazifa qo`shish",
    begin_date: "Boshlanish vaqti",
    end_date: "Tugash muddati",
    task_comment_index: "Topshiriq bo`yicha qilingan ishlar",
    description: "Izoh",
    file: "Fayl",
  },
  permission: {
    index: "Ruxsatlar",
    name: "Ruxsat nomi",
    display_name: "Ko`rinish nomi",
    description: "Izox",
    dialogHeaderText: "Yangi ruxsat qo`shish",
    edit: "Tahrirlash",
  },
  role: {
    index: "Rollar",
    name: "Sarlavha roli",
    display_name: "Ko`rsatiladigan ism",
    description: "Eslatma",
    assigned_permissions: "Biriktirilgan ruxsatnomalar",
    not_assigned_permissions: "Biriktirilmagan ruxsatnomalar",
    assigned_roles: "Biriktirilgan rollar",
    not_assigned_roles: "Biriktirilmagan rollar",
    assign_role: "Rol birirktirish",
    assign_permission: "Ruxsatnoma biriktirish",
    dialogHeaderText: "Ruxsatnomalarni rolga tayinlang",
    addRole: "Yangi rol qo`shish",
  },
  department: {
    functional_parent_id: "Funksional rahbariyat",
    parent_id: "Asosiy bo`lim",
    name: "Bo`lim nomi",
    department_type_id: "Bo`lim turi",
    director: "Direktor",
    company_id: "Tashkilot",
    dialog: "Bo`limlar",
    index: "Bo`limlar",
    create: "Yangi bo`lim yaratish",
    company_name: "Tashkilot nomi",
    department_code: "Bo`lim kodi",
    department_name: "Bo`lim nomi",
    departments: "Bo`limlar",
    tree: "Bo`limlar iyerarhiyasi",
    manager_staff: "Rahbar shtati",
    management: "Boshqaruvchi xodimlar",
    itr: "Inj-tex-xodim",
    restitr: "Inj-tex-xodim qolganlari",
    rab: "Ishchi",
    master: "Master",
    brigadir: "Brigadir",
    worker: "Ishchi",
    internship: "Amaliyotda",
    foreigner: "Xorijliklar",
    maternity: "Dekret",
    women: "Ayollar",
    accountant: "Hisobchi",
    branch: "Filial",
  },
  departments: {
    name: "Bo`lim nomi",
    department_type_id: "Bo`lim turi",
    parent_id: "Asosiy bo`lim",
    director: "Direktor",
    company_id: "Tashkilot",
    dialog: "Bo`limlar",
    index: "Bo`limlar",
    create: "Yangi bo`lim yaratish",
    company_name: "Tashkilot nomi",
    department_code: "Bo`lim kodi",
    department_name: "Bo`lim nomi",
    departments: "Bo`limlar",
    tree: "Bo`limlar iyerarhiyasi",
    manager_staff: "Rahbar shtati",
  },
  company: {
    index: "Tashkilot",
    dialogHeaderText: "Tashkilot",
    name: "Tashkilot nomi",
    newOrganization: "Yangi tashkilot",
    updateCompany: "Tashkilotni taxrirlash",
  },
  staff: {
    hr: "Kadrlar boshqaruvi",
    name_uz_latin: "O`zbek lotin",
    name_uz_cyril: "O`zbek kiril",
    name_ru: "Ruscha",
    department_id: "Bo`limi",
    position_id: "Kasb",
    range_id: "Diapazon",
    personal_type_id: "Personal turi",
    expence_type_id: "Xarajat turi",
    rate_count: "Stavka soni",
    personal_count: "Personal sanog`i",
    order_date: "Buyruq sanasi",
    order_number: "Buyruq raqami",
    begin_date: "Boshlanish sanasi",
    end_date: "Tugallanish sanasi",
    editStaff: "Taxrirlash",
    newStaff: "Yangi kiritish",
    staff: "Shtatlar jadvali",
    staff_history: "Hodimning shtatlar jadvali tarixi",
    staff_admin: "Tashkiliy boshqaruv",
    index: "Shtat jadvali",
    admin: "Admin panel",
    range_code: "Razryad",
    expence_type: "Harajat turi",
    personal_type: "Personal turi",
    employees_count: "Хodim soni",
    branch_id: "Filial",
    requirements: "Kandidat uchun talablar",
    instruction_file_path: "Yo‘riqnoma fayliga xavola",
    uploadFiles: "Faylni yuklash",
    fileType: "Fayl turi",
    file: "Fayl",
    files: "Fayllar",
    objectType: "Ob`ekt turi",
  },
  documentTypes: {
    list: "Ro`yxat",
    name: "Nomi",
    index: "Hujjat turlari",
    dialogHeaderText: "Hujjat turlari",
  },
  document: {
    pre_agreement: "Oldindan kelishuv",
    arrive_date: "Kelib tushgan sana",
    signed_at: "Imzolangan sana",
    eimzo_info: "Raqamli imzo haqida ma`lumotlar",
    add_parent_document: "Aloqador hujjatlar",
    children_document: "Quyi aloqador hujjatlar",
    add_doer: "Bajaruvchi qo`shish",
    document_sending_department: "Yuboruvchi",
    document_number: "Tartib raqami",
    letter_number: "Xat raqami",
    create: "Yangi hujjat yaratish",
    new_document: "Yangi hujjat",
    new_documents: "Yangi hujjatlar",
    news_document: "Yangi hujjatlar",
    parent_emlpoyee: "Yo`naltiruvchi xodim",
    edit_document: "Tahrirlash",
    edit_title: "Mavzuni o`zgartirish",
    document_type_id: "Hujjat turi",
    document_created_by: "Yaratgan foydalanuvchi:",
    created_at: "Hujjat yaratilgan sana:",
    confirmers: "Eimzo ma`lumotlari",
    document_receiving_department: "Qabul qiluvchilar",
    attachments: "Yuklama",
    title: "Hujjat sarlavhasi",
    document_content: "Hujjatning kontent qismi",
    short_content: "Qisqacha mazmuni",
    number: "Chiquvchi raqam",
    correspondent: "Korrespondent",
    type: "Turi",
    should_receive_all_departments: "Barcha bo`limlarga yuborish",
    documents: "Hujjatlar",
    document_type: "Turi",
    document_template: "Hujjat shablonlari",
    document_date: "Sanasi",
    department: "Boshqaruv",
    department_send: "Yuboruvchi",
    department_receiver: "Qabul qiluvchi",
    index: "Hujjatlar",
    comment: "Izohlar",
    assignment: "Topshiriq",
    content: "Qisqacha mazmuni",
    reject: "Bekor qilish",
    documentTypes: "Hujjat turlari",
    documentTemplates: "Andozalar",
    action_type: "Amal",
    signer_sequence: "Navbat",
    staff: "Bo`limlar, lavozimlar",
    signers: "Imzolovchilar",
    history: "Hujjat tarixi",
    creator: "Yaratuvchisi",
    description: "Hujjat tavsifi",
    reaction: "Amal",
    update: "Yangilash",
    resolution: "Yo`naltirish",
    resolution_create: "Yo`naltirilgan vaqti",
    due_date: "Muddati",
    files: "Fayllar",
    add_file: "Fayl qo`shish",
    file_name: "Fayl nomi",
    full_search: "To`liq qidiruv",
    accept: "Tasdiqlayman",
    processing: "Jarayonda",
    vacancy: "Bo`sh joy",
    doc_punkts: "Hujjat punktlari",
    punkt: "Punkt",
    add_punkt: "Punkt qo`shish",
    resolution_type: "Yo`naltirish turi",
    inboxs: "Kiruvchi hujjatlar",
    outboxs: "Chiquvchi hujjatlar",
    drafts: "Qoralama hujjatlar",
    cancels: "Bekor qilingan hujjatlar",
    inbox: "Kiruvchi",
    outbox: "Chiquvchi",
    draft: "Qoralama",
    expected: "Kutilayotgan hujjatlar",
    canceled: "Bekor qilingan",
    all: "Barcha hujjatlar",
    allhr: "Barcha dok.(Kadr)",
    alllsp: "Barcha dok.(LSP)",
    allznz: "Barcha doc.(ZNZ)",
    status: "Status",
    term: "Muddat (soat)",
    add_signer: "Imzolovchilarni qo`shish",
    document_name: "Hujjat nomi",
    add_parent: "Bevosita rahbarlarni qo`shish",
    empty_employee: "Xodim qo`shilmadi",
    empty_document_relation: "Hujjat ilova qilinishi kerak",
    add_employee: "Xodimni qo`shing",
    send: "Yuborish",
    confirmation: "Tasdiqlash",
    return_to: "Ijroga qaytarish",
    copy: "Hujjatdan nusxa olish",
    not_set: "O`rnatilmagan",
    start_date: "Boshlanish sanasi",
    end_date: "Tugash sanasi",
    new: "Yangi",
    ok: "Tasdiqlangan",
    cancel: "Bekor qilish",
    process: "Jarayonda",
    doers: "Ijrochilar",
    doer: "Ijrochi",
    reaction_status: "Imzolash statusi",
    pending_action: "Kutilayotgan harakat",
    total_sum: "Haqiqiy summa",
    out_of_control: "Nazoratdan olish",
    is_pdf_portrait: "PDF portretmi?",
    edit_attribute: "Qo`shimcha atributlar",
    when_who: "Kim tomonidan va qachon imzolangan",
    eri_given: "ERI kim tomonidan berilgan",
    eri_serial: "ERI seriya raqami",
    eri_valid_date: "ERI amal qilish muddati",
    eri_info: "Hujjatning e-imzo ma`lumotlari",
    previous_version: "Oldingi versiya",
    company_outbox: "Chiquvchi (Uzavtosanoat)",
    add_resolution: "Qo`shish",
    "no-access": "Sizda ushbu hujjatni ko'rish uchun ruxsat yo'q!",
    back: "Orqaga",
    wrong_password: "Parol xato!",
  },
  home: {
    home: "Asosiy",
  },
  tariffScale: {
    index: "Tarif setkasi",
    dialogHeaderText: "Tariflar",
    category: "Kategoriyasi",
    salary: "Maosh",
    order_date: "Buyruq sanasi",
    order_number: "Buyruq raqami",
    create: "Yangi tarif setkasi qo`shish",
    edit: "Tahrirlash",
  },
  coefficient: {
    index: "Ustamalar",
    dialogHeaderText: "Ustamalar",
    code: "Kodi",
    percent: "Foiz",
    description: "Izoh",
    order_date: "Buyruq sanasi",
    order_number: "Buyruq raqami",
    status: "Holat",
    begin_date: "Boshlanish vaqti",
    end_date: "Tugash muddati",
    create: "Yangi ustama qo`shish",
    edit: "Tahrirlash",
    type: "Ustama turi",
  },
  departmentTypes: {
    index: "Bo`lim turlari",
    dialogHeaderText: "Bo`lim turlari",
    create: "Yangi bo`lim turi kiritish",
    edit: "Tahrirlash",
  },
  personalType: {
    index: "Personal turlari",
    dialogHeaderText: "Personal turlari",
    create: "Yangi personal turi kiritish",
    edit: "Tahrirlash",
  },
  positionType: {
    index: "Lavozim turlari",
    dialogHeaderText: "Lavozim turlari",
    code: "Kodi",
    create: "Yangi lavozim turini kiritish",
    edit: "Tahrirlash",
  },
  position: {
    index: "Lavozimlar",
    updatePosition: "Tahrirlash",
    newPosition: "Yangi lavozim qo`shish",
  },
  expenceType: {
    index: "Xarajat turlari",
    dialogHeaderText: "Xarajat turlari",
    create: "Yangi xarajat turi qo`shish",
    edit: "Tahrirlash",
  },
  ranges: {
    index: "Diapazonlar",
    dialogHeaderText: "Diapazon",
    code: "Kodi",
    name: "Diapazon nomi",
    minfond: "Minfond",
    maxfond: "Maxfond",
    order_date: "Buyruq sanasi",
    order_number: "Buyruq raqami",
    create: "Yangi diapazon kiritish",
    edit: "Tahrirlash",
  },
  recruitment: {
    index: "Ishga yollash",
  },
  transfer: {
    index: "Tarjima",
    transfer_employee: "Hodimlarni boshqa shtatga o`t kazish",
    create_document: "Dokument yasash",
    new_staff: "Yangi Shtat",
    employee: "Hodim",
  },
  dismissal: {
    index: "Ishdan bo`shatish",
  },
  countries: {
    index: "Davlat",
    dialogHeaderText: "Davlat",
    country_code: "Davlat kodi",
  },
  districts: {
    index: "Shahar/tuman",
    region_id: "Viloyat",
    dialogHeaderText: "Shahar/tuman",
  },
  regions: {
    index: "Viloyat",
    dialogHeaderText: "Viloyat",
    country_id: "Davlat",
    region_id: "Viloyat",
  },
  natioanalities: {
    index: "Millat",
    dialogHeaderText: "Millat",
    name_uz_latin: "O`zbekcha lotin",
  },
  employeeDocument: {
    index: "Xodimning hujjatlari",
    tabel: "Tabel raqami :",
    born_date: "Tug`ilgan sanasi :",
    department: "Bo`lim :",
    employee_position: "Lavozimi :",
    series: "Seriya",
    number: "Raqam",
    given_by: "Kim tomonidan berilgan",
    date_issue: "Berilgan sana",
    valid_until: "Amal qilish muddati",
    status: "Status",
    actions: "Amal",
    addEmployeeDocument: "Yangi hujjatlar qo`shish",
    updateEmployeeDocument: "Tahrirlash",
    docType: "Hujjat turi",
    title: "Sarlavha",
    files: "Fayllar",
    uploadFiles: "Fayllarni yuklash",
  },
  signerGroup: {
    signer_group_id: "Imzo qoyuvchilar guruhi",
    new_signer_group: "Imzolovchilar guruhini yarating",
    edit_signer_group: "Imzolovchilar guruhini tahrirlash",
    staff_list: "Xodimlar ro`yxati",
    add_signer: "Imzolovchilarni qo`shish",
  },
  purchase_catalogs: {
    catalogs: "Xarid kataloglari",
    add_catalog: "Xarid katalogi qo`shish ",
    edit_catalog: "Xarid katalogini tahrirlash",
    code: "Kodi",
    part_number: "Detal raqami",
    description: "Izoh",
    material_content: "Material tarkibi",
    name_eng: "Nomi ingliz tilida",
    name_ru: "Nomi rus tilida",
    specification: "Spetsifikatsiyasi",
    short_name: "Qisqa nomi",
    using_location: "Joylashuvdan foydalanish",
    using_purpose: "Maqsadli foydalanish",
    measure: "O`lchov",
    manufacturer: "Ishlab chiqaruvchi",
  },
  report: {
    create_document: "Yaratilgan hujjatlar",
    expired: "Muddati o`tgan",
    prosesing: "Ko`rib chiqilmoqda",
    waiting: "Kutish",
    completed_on_time: "Vaqtida bajarilgan",
    failed_in_time: "Vaqtida bajarilmagan",
  },
  currency: {
    index: "Valyutalar",
    title: "Valyuta turlari",
    name: "Nomi",
    code: "Kodi",
    description: "Tavsifnoma",
    add: "Yangi valyuta turi qo`shish",
    edit: "Tahrirlash",
    delete: "Siz ushbu valyuta turini o`chirishga aminmisiz?",
    history: "Valyutalar tarixi",
    price: "Kurs",
    date: "Sana",
    search: "Qidiruv",
    oper: "Amallar",
  },
  notification: {
    index: "Xabarnomalar",
    content: "Tarkib",
    is_active: "Xolat",
    create: "Yangi xabarnoma qo`shish",
    edit: "Tahrirlash",
    delete: "Siz ushbu xabarnomani o`chirishga aminmisiz?",
  },
  accessDepartment: {
    index: "Xodimlar ruxsati",
    employee: "Xodimlar ro`yxati",
    department: "Bo`limlar ro`yxati",
    access: "Ruxsatlar",
    accessType: "Ruxsat turi",
    create: "Yangi ruxsat qo`shish",
    tabel: "Tabel raqami",
  },
  accessType: {
    index: "Ruxsat turlari",
    create: "Yangi ruxsat turi qo`shish",
    edit: "Tahrirlash",
  },
  registry: {
    index: "Reestr",
    vacation: "Tatil buyrug` reestri",
    business_trip: "H.safari buyrug` reestri",
    work_calendar: "Ish kalendari",
  },
  carPurchase: {
    ERI: "Elektron imzo olish",
    index: "Avtomobil sotib olish uchun ariza",
    view: "Avtomobil uchun ariza",
    employee: "Xodim",
    carModel: "Avtomobil modeli",
    comment: "Izoh",
    status: "Xolat",
    regStatus: "Reestr xolati",
    accEmployee: "Xisobchi",
    employeeId: "Xodim",
    file: "Fayl",
    files: "Fayllar",
    create: "Yangi ariza qo`shish",
    createdBy: "Yaratgan foydalanuvchi",
    createdAt: "Sana",
    department: "Bo`lim",
    position: "Lavozim",
    edit: "Tahrirlash",
  },
  blankTemplate: {
    index: "Blanka shablonlari",
    create: "Blanka yaratish",
    get: "Blankalar ro`yxati",
    blank_name: "Blanka nomi",
    status: "Status",
    attribute_name: "Atribut nomi",
    parameter_name: "Parametr nomi",
    cell_address:
      "Yacheyka manzili (masalan: " + Math.floor(Math.random() * 19 + 1) + ")",
    data_type_id: "Atribut turi",
    file: "File",
    fileType: "File turi",
  },
  company_requisites: {
    name_uz_latin: "Nomi o`zbek lotin",
    name_uz_cyril: "Nomi o`zbek kiril",
    name_ru: "Nomi ruscha",
    name: "Nomi",
    address_name_uz_latin: "Manzil o`zbek lotin",
    address_name_uz_cyril: "Manzil o`zbek kiril",
    address_name_ru: "Manzil ruscha",
    address: "Manzili",
    inn: "INN raqami",
    account: "Hisob raqami",
    swift: "SWIFT kodi",
    oknh: "OKNH",
    mfo: "MFO",
    add_requisites: "Yangi rekvizit qo`shish",
    edit: "Tahrirlash",
    delete: "Удалить",
  },
  reportDepartment: {
    all: "Barchasi",
    published: "E`lon qilingan",
    processing: "Jarayonda",
    signed: "Imzolangan",
    ready: "Tayyor",
    completed: "Yakunlangan",
    cancelled: "Rad edilgan",
  },
  familyRelatives: {
    index: "Oilaviy qarindoshlar",
    status: "Holat",
    add: "Oilaviy qarindoshlar qo`shish",
  },
  inventory: {
    index: "Inventarizatsiya",
    warehouse: "Ombor",
    comission: "Komissiya",
    blank_number: "Blanka raqami",
    part_number: "Part NO",
    product_name: "Masxulot nomi",
    real_stock: "Sanoq",
    address: "Adres",
    addressName: "Adres nomi",
    delete: "Siz ushbu adresni o`chirishga aminmisiz?",
    product: "Maxsulotlar ro`yxati",
    partNumber: "Detal raqami",
    productName: "Maxsulot nomi",
    stock: "Qoldiq",
    unitMeasure: "O`lchov birligi",
    report: "Hisobot",
    commission: "Komissiya",
    blank: "Blankalar",
  },
  directory: {
    index: "Jurnallar",
    type: "Jurnal turi",
  },
  timeline: {
    index: "Lenta",
    no_title: "Nomsiz",
    active: "Aktiv",
    inactive: "Aktiv emas",
    no_comment: "Hozircha izohlar yo`q",
    comments: "Izohlar",
    leave_comment: "Izoh qoldirish",
    to_comment: " ga izoh",
  },
  hr_language: {
    index: "Xorijiy til",
    add: "Xorijiy til qo`shish",
  },
  hr_party: {
    index: "Partiya",
    add: "Partiya qo`shish",
  },
  hr_study_type: {
    index: "Ta`lim turlari",
    add: "Ta`lim turlarini qo`shish",
  },
  hr_state_award: {
    index: "Davlat mukofotlari",
    add: "Davlat mukofotlari qo`shish",
  },
  hr_military_rank: {
    index: "Harbiy unvon",
    add: "Harbiy unvon qo`shish",
  },
  sap_transaction: {
    index: "Bitim ro`li (SAP)",
    add: "Ro`l qo`shish",
  },
  hr_university: {
    index: "Oliy o`quv yurti",
    add: "Oliy o`quv yurtini qo`shish",
  },
  hr_major: {
    index: "Ma`lumoti bo`yicha mutaxassisligi",
    add: "Mutaxassisligni qo`shish",
  },
  hr_study_degree: {
    index: "Ta`lim darajasi",
    add: "Ta`lim darajasi qo`shish",
  },
  reports: {
    index: "Hisobot yaratish",
    conditions: "Shartlar",
    columns: "Ustunlar",
    column_table: "Ma`lumot olinadigan jadval",
    column_name: "Ma`lumot ustunti",
    additions: "Qo`shimchalar",
    document_detail_employee: "Hujjat hodimlaridan",
    document_detail_content: "Hujjat xususiyatlaridan",
  },
  joint_venture: {
    index: "Tizim korhonasi",
    name: "Tizim korhonasi nomi",
    create: "Yangi yaratish",
    edit: "O`zgartirish",
    manager: "Raxbari",
    manager_fio: "Raxbari F.I.O.",
  },
  critical: {
    index: "Staff Critical",
    count: "Soni",
    employee: "Xodim",
    position: "Lavozim",
    begin_date: "Boshlanish sanasi",
    end_date: "Tugash sanasi",
    description: "Izoh",
    add_reserve_employee: "Zaxiraga yangi xodim qo`shish",
  },
  control_punkt: {
    add: "Kontrol punktini qo`shing",
    edit: "Kontrol punktini o`zgartirish",
    name: "Kontrol punkt",
    content: "Tarkib",
    priority: "Ustuvor",
    punkt_type: "Punkt turi",
    journal: "Jurnal",
    controller: "Nazorat uchun javobgar",
    nominated: "Nomzod",
    distribution_list: "Ijrochilar ro`yxati",
  },
  reports_executions: {
    sidebar: "Hujjatlarning bajarilishi bo'yicha batafsil hisobot",
    date: "Дата Документa",
    number: "Регистрационный номер",
    type: "Тип Документа Приказ",
    fullname: "Ответственны й исполнитель",
    department_head: "Подразделение",
    due: "Срок исполненИя",
    yesNo: "Отметка  об исполнении, Да/Нет",
    at: "Отметка  об исполнении, Дата исполнеНИЯ",
    file: "Скрепка файлисполнителя",
    type_name: "type_name_uz_latin",
    department: "department_uz_latin",
    yes: "Yes",
    no: "No",
    fio: "fio_uz_latin",
  },
  reports_contracts: {
    title: "Shartnoma bo'yicha to'liq xisobot",
    sidebar: "Shartnoma bo'yicha to'liq xisobot",
    date: "Дата документa",
    number: "Номер",
    type: "Тип документа",
    fullname: "Ответственный исполнитель",
    department_head: "Подразделение",
    due: "Срок исполнения",
    yesNo: "Отметка  об исполнении",
    at: "Дата исполнения",
    file_doc: "Скрепка документа",
    file: "Скрепка файл исполнителя",
    type_name: "type_name_ru",
    department: "department_ru",
    yes: "Yes",
    no: "No",
    fio: "fio_ru",
    company: "Компания",
    contragent: "Контрагент/вид расхода",
    dogovor: "Договор",
    date_contract: "Дата договора",
    valyuta: "Валюта (кроме UZS)",
    valyuta_summ: "Валютная сумма",
    summa: "Сумма",
    category: "Категория",
    description: "Примечание",
    "field-title": "Title",
    contractor: "contractor",
    notes: "notes",
  },
  telegram: "Telegram guruhi",
  tutorial: "Qo'llanma",
  phonebook: "Ichki telefon raqamlari",
  copyClipboard: "Hujjat raqamidan nusxa olish",
  "new-edo": "Virtual Office (Yangi EDO)",
};
module.exports = uz_latin;
