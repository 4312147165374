import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    state: {
        backend_url: process.env.VUE_APP_BACKEND_URL,
        access_token: Cookies.get('access_token') || "null",
        AS400_url: 'https://edo-db2.uz/',
        COMPANY_NAME_RU: "ООО «Roodell»",
        COMPANY_NAME_UZ_LATIN: "«Roodell» MCHJ",
        COMPANY_NAME_UZ_CYRIL: "«Roodel» МЧЖ",
        EIMZO_DOMAIN: "edo.roodell.uz",
        EIMZO_API_KEY: 'EAA28B906D82405A9F84E0EBACF1A37F7D97DBFE6292EF5A735F49394EFD25DD8746F53FECDDEF1AE105B4A8807E244B0C21763998B52A0BCCEBEC913C640B7D',
        BACKEND_URL: "",
        COMPANY_ID: 1,
        PHONE_IT: "1111, 2222",
        PHONE_PM: "3333",
        PHONE_PM_TASHKENT: "4444",
        user: null,
        permissions: null,
        roles: null,
        tabel_length: 4,
        locale: Cookies.get('locale') || 'ru',
        errors: [],
        redirect_url: null,
        document_list: [],
        notifications: {}
    },
    getters: {
        checkPermission: (state) => (permission) => {
            let localStorage = window.localStorage;

            let permissions = JSON.parse(localStorage.getItem('permissions'));
            if (permissions)
                return permissions.find(v => v == permission);
            return false;
            // let permissions = state.permissions;
            // if (permissions)
            //   return permissions.find(v => v == permission);
            // return false;
        },
        checkRole: (state) => (role) => {
            let localStorage = window.localStorage;
            let roles = JSON.parse(localStorage.getItem('roles'));
            // let roles = state.roles
            if (roles)
                return roles.find(v => v.name == role);
            return false;
        },
        getUser: (state) => () => {
            let localStorage = window.localStorage;
            let user = JSON.parse(localStorage.getItem('user'));
            if (user)
                return user;
            return false;
        },
	getEimzoKey: (state) => () => {
            let localStorage = window.localStorage;
            const now = new Date();
            let eimzo_key = localStorage.getItem('eimzo_key');
            let expire = localStorage.getItem('expire_eimzo_key');
            if (eimzo_key == null || expire == null) {
                return null;
            } else {
                if (expire <= now.getTime()) {
                    return null;
                } else return eimzo_key;
            }
        },
        getRoles: (state) => () => {
            let localStorage = window.localStorage;
            let roles = JSON.parse(localStorage.getItem('roles'));
            if (roles)
                return roles;
            return false;
        },

        getAccessToken: (state) => () => {
            let localStorage = window.localStorage;
            let access_token = localStorage.getItem('access_token');
            if (access_token)
                return access_token;
            return false;
        },
        // getBooks:(state) => state.books,
    },
    mutations: {
        setUser: (state, arg) => {
            let localStorage = window.localStorage;
            localStorage.setItem('user', JSON.stringify(arg));

            if(!arg){
                let localStorage = window.localStorage;
                localStorage.setItem('eimzo_key', JSON.stringify(arg));
            }
            // state.user = arg;
        },
        setPermissions: (state, arg) => {
            let localStorage = window.localStorage;
            localStorage.setItem('permissions', JSON.stringify(arg));
            // state.permissions = arg;
        },
        setRole: (state, arg) => {
            let localStorage = window.localStorage;
            localStorage.setItem('roles', JSON.stringify(arg));
            // state.roles = arg;
        },
        setDocumentList: (state, arg) => {
            state.document_list = arg;
        },
        setNotifications: (state, arg) => {
            state.notifications = arg;
        },
	setEimzoKey: (state, arg) => {
            let localStorage = window.localStorage;
            localStorage.setItem('eimzo_key', arg);
            if (!!arg) {
                const now = new Date();
                localStorage.setItem('expire_eimzo_key', now.getTime() + 18000000);
            }
        },
        setAccessToken: (state, arg) => {
            let localStorage = window.localStorage;
            localStorage.setItem('access_token', arg);
        },
        setRedirectUrl(state, arg) {
            state.redirect_url = arg
        },
    },
    actions: {
        setLocale: (state, arg) => {
            Cookies.set('locale', arg)
        },
        setPermissions: (context, arg) => {
            context.commit('setPermissions', arg)
        },
        setRole: (context, arg) => {
            context.commit('setRole', arg)
        },
	 setEimzoKey: (context, arg) => {
            context.commit('setEimzoKey', arg)
        },
        setDocumentList: (context, arg) => {
            context.commit('setDocumentList', arg)
        },
        setNotifications: (context, arg) => {
            context.commit('setNotifications', arg)
        },
        setUser: (context, arg) => {
            context.commit('setUser', arg)
        },
        setAccessToken: (context, arg) => {
            context.commit('setAccessToken', arg)
        },
        setRedirectUrl: (context, arg) => {
            context.commit('setRedirectUrl', arg)
        },
    },
    modules: {}
})
